
const ACCOUNT_MIGRATION_DATE_END = {
    dateOne: 'January 31, 2024',
    dateTwo: '31 January 2024',
    dateThree: new Date(2024, 1, 1, 0, 0, 0)
}

const ACCOUNT_MIGRATION_STATUSES = {
    pending: 'Pending',
    inProgress: 'In Progress',
    failed: 'Failed',
    completed: 'Completed',
}

export {
    ACCOUNT_MIGRATION_STATUSES,
    ACCOUNT_MIGRATION_DATE_END,
}
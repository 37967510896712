<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :fill="getColor(hover)" fill-rule="evenodd" clip-rule="evenodd" d="M2.80494 2.33466C2.41863 2.09151 1.90219 2.13814 1.5658 2.47453C1.17527 2.86506 1.17527 3.49822 1.5658 3.88875L3.48059 5.80354C1.90725 6.545 0.818359 8.14525 0.818359 9.99982C0.818359 12.5604 2.89413 14.6362 5.45472 14.6362H8.182C8.73428 14.6362 9.182 14.1885 9.182 13.6362C9.182 13.0839 8.73428 12.6362 8.182 12.6362H5.45472C3.9987 12.6362 2.81836 11.4558 2.81836 9.99982C2.81836 8.67497 3.79561 7.57836 5.06859 7.39154L6.67687 8.99982H6.36381C5.81153 8.99982 5.36381 9.44754 5.36381 9.99982C5.36381 10.5521 5.81153 10.9998 6.36381 10.9998H8.67687L10.9051 13.2281C10.8494 13.3527 10.8184 13.4908 10.8184 13.6362C10.8184 14.1885 11.2661 14.6362 11.8184 14.6362H12.3132L15.2022 17.5251C15.5927 17.9156 16.2259 17.9156 16.6164 17.5251C16.9184 17.2231 16.9868 16.7761 16.8218 16.4083C16.7565 16.37 16.695 16.3228 16.639 16.2668L3.00259 2.6304C2.91519 2.54299 2.84931 2.44222 2.80494 2.33466ZM16.924 13.9805C18.2766 13.1706 19.182 11.691 19.182 9.99982C19.182 7.43923 17.1062 5.36346 14.5456 5.36346H11.8184C11.2661 5.36346 10.8184 5.81117 10.8184 6.36346C10.8184 6.91574 11.2661 7.36346 11.8184 7.36346H14.5456C16.0017 7.36346 17.182 8.5438 17.182 9.99982C17.182 11.1463 16.4502 12.1218 15.4283 12.4848L16.924 13.9805ZM13.9065 10.963C14.3276 10.8452 14.6365 10.4586 14.6365 9.99982C14.6365 9.44754 14.1888 8.99982 13.6365 8.99982H11.9433L13.9065 10.963Z"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'LinkOffIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

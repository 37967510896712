<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M2.5 12.5H9.16667" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M2.5 9.16699H17.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'MenuIcon',
  inheritAttrs: false,
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" class="icon-sort" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M6 15L5.29289 15.7071C5.68342 16.0976 6.31658 16.0976 6.70711 15.7071L6 15ZM7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5H7ZM8.70711 13.7071C9.09763 13.3166 9.09763 12.6834 8.70711 12.2929C8.31658 11.9024 7.68342 11.9024 7.29289 12.2929L8.70711 13.7071ZM4.70711 12.2929C4.31658 11.9024 3.68342 11.9024 3.29289 12.2929C2.90237 12.6834 2.90237 13.3166 3.29289 13.7071L4.70711 12.2929ZM10 4C9.44772 4 9 4.44772 9 5C9 5.55228 9.44772 6 10 6V4ZM15 6C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4V6ZM10 7.5C9.44772 7.5 9 7.94772 9 8.5C9 9.05228 9.44772 9.5 10 9.5V7.5ZM13 9.5C13.5523 9.5 14 9.05228 14 8.5C14 7.94772 13.5523 7.5 13 7.5V9.5ZM7 15V5H5V15H7ZM6.70711 15.7071L8.70711 13.7071L7.29289 12.2929L5.29289 14.2929L6.70711 15.7071ZM6.70711 14.2929L4.70711 12.2929L3.29289 13.7071L5.29289 15.7071L6.70711 14.2929ZM10 6H15V4H10V6ZM10 9.5H13V7.5H10V9.5Z"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'SortDownIcon',
  inheritAttrs: false,
}
</script>

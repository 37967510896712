<template>
  <v-hover #default="{ hover }">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.828 2.172L12.172 7.828M11 5C11 7.20914 12.7909 9 15 9C17.2091 9 19 7.20914 19 5C19 2.79086 17.2091 1 15 1C12.7909 1 11 2.79086 11 5Z" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.9698 14.2304V16.4885C16.9707 16.6981 16.9277 16.9056 16.8437 17.0977C16.7598 17.2898 16.6366 17.4622 16.4821 17.6039C16.3277 17.7456 16.1453 17.8535 15.9467 17.9207C15.7481 17.9878 15.5377 18.0128 15.3289 17.9939C13.0127 17.7422 10.7879 16.9508 8.83311 15.6831C7.01446 14.5274 5.47255 12.9855 4.3169 11.1669C3.04482 9.20324 2.25318 6.96756 2.00611 4.64096C1.9873 4.43282 2.01204 4.22303 2.07875 4.02497C2.14545 3.82691 2.25267 3.6449 2.39357 3.49055C2.53448 3.33619 2.70598 3.21287 2.89715 3.12843C3.08833 3.04398 3.29499 3.00027 3.50399 3.00007H5.76209C6.12738 2.99648 6.48152 3.12583 6.75849 3.36403C7.03546 3.60222 7.21637 3.93301 7.26749 4.29472C7.3628 5.01736 7.53956 5.72691 7.79439 6.40981C7.89566 6.67922 7.91757 6.97202 7.85754 7.2535C7.79751 7.53498 7.65804 7.79336 7.45567 7.99801L6.49974 8.95394C7.57125 10.8384 9.13153 12.3986 11.0159 13.4702L11.9719 12.5142C12.1765 12.3118 12.4349 12.1724 12.7164 12.1123C12.9979 12.0523 13.2907 12.0742 13.5601 12.1755C14.243 12.4303 14.9525 12.6071 15.6752 12.7024C16.0408 12.754 16.3747 12.9381 16.6134 13.2199C16.8521 13.5016 16.979 13.8612 16.9698 14.2304Z" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'Block-icon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

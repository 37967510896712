<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8307 12.5H12.4974M4.16406 17.5V4.16667C4.16406 3.72464 4.33966 3.30072 4.65222 2.98816C4.96478 2.67559 5.3887 2.5 5.83073 2.5H14.1641C14.6061 2.5 15.03 2.67559 15.3426 2.98816C15.6551 3.30072 15.8307 3.72464 15.8307 4.16667V17.5L13.3307 15.8333L11.6641 17.5L9.9974 15.8333L8.33073 17.5L6.66406 15.8333L4.16406 17.5ZM7.4974 5.83333H12.4974H7.4974ZM7.4974 9.16667H12.4974H7.4974Z" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Billing-icon',
  inheritAttrs: false,
}
</script>
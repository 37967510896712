<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99709 2.5C11.9436 4.22215 14.4842 5.11881 17.0804 5C17.4584 6.28585 17.5741 7.63456 17.4205 8.96598C17.267 10.2974 16.8474 11.5844 16.1866 12.7504C15.5258 13.9165 14.6374 14.9378 13.5741 15.7537C12.5109 16.5696 11.2944 17.1635 9.99709 17.5C8.69977 17.1635 7.48333 16.5696 6.42005 15.7537C5.35677 14.9378 4.46835 13.9165 3.80759 12.7504C3.14683 11.5844 2.72721 10.2974 2.57366 8.96598C2.42011 7.63456 2.53577 6.28585 2.91376 5C5.51004 5.11881 8.05057 4.22215 9.99709 2.5" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.9974 9.9987C10.4576 9.9987 10.8307 9.6256 10.8307 9.16536C10.8307 8.70513 10.4576 8.33203 9.9974 8.33203C9.53716 8.33203 9.16406 8.70513 9.16406 9.16536C9.16406 9.6256 9.53716 9.9987 9.9974 9.9987Z" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 10V12.0833" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'SecurityIcon',
  inheritAttrs: false,
}
</script>
<template>
  <svg v-bind="$attrs" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="stroke-to-change" d="M35.0007 64.1668C51.109 64.1668 64.1673 51.1085 64.1673 35.0002C64.1673 18.8919 51.109 5.8335 35.0007 5.8335C18.8923 5.8335 5.83398 18.8919 5.83398 35.0002C5.83398 51.1085 18.8923 64.1668 35.0007 64.1668Z" stroke="#383839" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="stroke-to-change" d="M35 22.3335V40.3335" stroke="#383839" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="stroke-to-change" d="M35 48.3335H35.0292" stroke="#383839" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>
<script>
export default {
  name: 'AlarmIcon',
  inheritAttrs: false,
}
</script>

<template>
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.875 25.1C48.0352 20.8443 45.744 17.0121 42.3927 14.2581C39.0413 11.5041 34.8378 9.99902 30.5 10C23.275 10 17 14.1 13.875 20.1C10.2006 20.4971 6.80252 22.238 4.33372 24.9883C1.86493 27.7386 0.499571 31.3042 0.5 35C0.5 43.275 7.225 50 15.5 50H48C54.9 50 60.5 44.4 60.5 37.5C60.5 30.9 55.375 25.55 48.875 25.1ZM27.275 40.725C27.0437 40.9568 26.769 41.1406 26.4666 41.2661C26.1641 41.3915 25.8399 41.4561 25.5125 41.4561C25.1851 41.4561 24.8609 41.3915 24.5584 41.2661C24.256 41.1406 23.9813 40.9568 23.75 40.725L18.5 35.5C18.2685 35.2685 18.0849 34.9938 17.9597 34.6914C17.8344 34.3889 17.7699 34.0648 17.7699 33.7375C17.7699 33.0764 18.0326 32.4424 18.5 31.975C18.9674 31.5076 19.6014 31.2449 20.2625 31.2449C20.9236 31.2449 21.5576 31.5076 22.025 31.975L25.5 35.45L36.7 24.25C37.1674 23.7826 37.8014 23.5199 38.4625 23.5199C39.1236 23.5199 39.7576 23.7826 40.225 24.25C40.6924 24.7174 40.9551 25.3514 40.9551 26.0125C40.9551 26.6736 40.6924 27.3076 40.225 27.775L27.275 40.725Z" fill="#9CE500"/>
  </svg>
</template>

<script>
export default {
  name: 'CloudSuccessIcon'
}
</script>
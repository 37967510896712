<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5998 4.16951L9.37816 4.16701C6.59149 4.16034 4.17233 6.42451 4.15316 9.16701V12.3253C4.15316 12.9837 4.06983 13.6262 3.71066 14.1737L3.47149 14.5387C3.10733 15.092 3.49899 15.8337 4.15316 15.8337H15.8448C16.499 15.8337 16.8898 15.092 16.5265 14.5387L16.2873 14.1737C15.929 13.6262 15.8448 12.9828 15.8448 12.3245V9.16784C15.8115 6.42451 13.3865 4.17617 10.5998 4.16951V4.16951Z" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5 15.834C12.5 16.497 12.2366 17.1329 11.7678 17.6018C11.2989 18.0706 10.663 18.334 10 18.334C9.33696 18.334 8.70107 18.0706 8.23223 17.6018C7.76339 17.1329 7.5 16.497 7.5 15.834" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.9987 1.66699C10.4407 1.66699 10.8646 1.84259 11.1772 2.15515C11.4898 2.46771 11.6654 2.89163 11.6654 3.33366V4.16699H8.33203V3.33366C8.33203 2.89163 8.50763 2.46771 8.82019 2.15515C9.13275 1.84259 9.55667 1.66699 9.9987 1.66699Z" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'Bell-icon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].chartTextColor
    }
  },
}
</script>

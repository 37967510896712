<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 14.168V14.1763" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.0026 11.249C9.98726 10.9785 10.0602 10.7103 10.2105 10.4848C10.3608 10.2594 10.5803 10.0889 10.8359 9.999C11.1492 9.87922 11.4303 9.68836 11.6572 9.44146C11.8842 9.19456 12.0507 8.89835 12.1437 8.57616C12.2367 8.25396 12.2537 7.91458 12.1932 7.58472C12.1328 7.25486 11.9966 6.94354 11.7954 6.67526C11.5942 6.40698 11.3334 6.18907 11.0337 6.03868C10.7339 5.88829 10.4034 5.80953 10.068 5.8086C9.73269 5.80767 9.40171 5.8846 9.10114 6.03332C8.80057 6.18204 8.53863 6.39851 8.33594 6.66567" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'SupportIcon',
  inheritAttrs: false,
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6.90909C5 4.90531 6.57956 3.27402 8.6411 2.89928L8.63743 2.85951L8.63636 1.90909C8.63636 1.40701 9.04338 1 9.54545 1C10.0475 1 10.4545 1.40701 10.4545 1.90909V2.81818L10.4507 2.89944C12.5118 3.27449 14.0909 4.90561 14.0909 6.90909C14.0909 7.41117 13.6839 7.81818 13.1818 7.81818C12.7156 7.81818 12.3314 7.46724 12.2788 7.01511L12.2727 6.90909C12.2727 5.6829 11.0767 4.63636 9.54545 4.63636C8.01423 4.63636 6.81818 5.6829 6.81818 6.90909C6.81818 8.08986 7.92727 9.10405 9.37674 9.17756L9.54545 9.18182C12.0308 9.18182 14.0909 10.9844 14.0909 13.2727C14.0909 15.2747 12.5141 16.9049 10.4553 17.2815L10.4545 18.2727C10.4545 18.7748 10.0475 19.1818 9.54545 19.1818C9.04338 19.1818 8.63636 18.7748 8.63636 18.2727L8.63656 17.2817C6.57724 16.9054 5 15.275 5 13.2727C5 12.7706 5.40701 12.3636 5.90909 12.3636C6.41117 12.3636 6.81818 12.7706 6.81818 13.2727C6.81818 14.4989 8.01423 15.5455 9.54545 15.5455C11.0767 15.5455 12.2727 14.4989 12.2727 13.2727C12.2727 12.092 11.1636 11.0778 9.71417 11.0043L9.54545 11C7.06007 11 5 9.19744 5 6.90909Z" :fill="getColor(hover)"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'DollarIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

<template>
  <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="$vuetify.theme.themes[isTheme].searchGray" d="M8.88972 14.4445C11.9581 14.4445 14.4455 11.9571 14.4455 8.88874C14.4455 5.8204 11.9581 3.33301 8.88972 3.33301C5.82137 3.33301 3.33398 5.8204 3.33398 8.88874C3.33398 11.9571 5.82137 14.4445 8.88972 14.4445Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="$vuetify.theme.themes[isTheme].searchGray" d="M15.8334 15.8334L12.8125 12.8125" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  }
}
</script>

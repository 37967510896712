import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'

import { getTimezones, getCountries } from '@/api/app-routes'

Vue.use(Vuex)

export const Types = {
  mutations: {
    SET_MESSAGE: `SET_MESSAGE`,
    CLEAR_MESSAGE: `CLEAR_MESSAGE`,
    SET_TIMEZONES: `SET_TIMEZONES`,
    SET_COUNTRIES: `SET_COUNTRIES`,
  },
  actions: {
    GET_TIMEZONES: 'GET_TIMEZONES',
    GET_COUNTRIES: 'GET_COUNTRIES',
  },
  getters: {
    GET_TIMEZONE_NAME_BY_ID: 'GET_TIMEZONE_NAME_BY_ID',
    GET_COUNTRY_NAME_BY_ID: 'GET_COUNTRY_NAME_BY_ID',
    GET_COUNTRY_BY_ID: 'GET_COUNTRY_BY_ID',
    GET_COUNTRY_WITH_CODE: 'GET_COUNTRY_WITH_CODE',
  }
}

const state = () => ({
  message: null,
  type: null,
  timezones: [],
  countries: [],
})

const getters = {
  [Types.getters.GET_TIMEZONE_NAME_BY_ID]: state => id => {
    const timezone = state.timezones.find(timezone => timezone.id === id)
    return timezone?.display ? timezone.display : ''
  },

  [Types.getters.GET_COUNTRY_NAME_BY_ID]: state => id => {
    const country = state.countries.find(country => country.id === id)
    return country?.name ? country.name : ''
  },

  [Types.getters.GET_COUNTRY_BY_ID]: state => id => state.countries.find(country => country.id === id),

  [Types.getters.GET_COUNTRY_WITH_CODE]: state => state.countries.map(country => ({
    ...country,
    name: `${country.name} (${country['num_code']})`
  }))
}

const mutations = {
  [Types.mutations.SET_MESSAGE](state, { message, type = 'success-bg' }) {
    state.message = message
    state.type = type
  },

  [Types.mutations.CLEAR_MESSAGE](state) {
    state.message = null
    state.type = null
  },

  [Types.mutations.SET_TIMEZONES](state, timezones) {
    state.timezones = timezones
  },

  [Types.mutations.SET_COUNTRIES](state, countries) {
    state.countries = countries
  },
}

const actions = {
  async [Types.actions.GET_TIMEZONES]({ commit, state }) {
    if (Array.isArray(state.timezones) && state.timezones.length) {
      return
    }

    const { success, payload, message } = await getTimezones()

    if (success) {
      commit('SET_TIMEZONES', payload)
    } else {
      !!message && commit(Types.mutations.SET_MESSAGE, { message, type: 'error-bg' })
    }
  },

  async [Types.actions.GET_COUNTRIES]({ commit }) {
    const { success, payload, message } = await getCountries()

    if (success) {
      commit('SET_COUNTRIES', payload)
    } else {
      !!message && commit(Types.mutations.SET_MESSAGE, { message, type: 'error-bg' })
    }
  },
}

const modules = {
  auth
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
})

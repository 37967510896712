 <template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-11 action-btn"
      :class="[hover
        ? 'actionButtonHoverBg actionButtonHoverColor--text'
        : 'actionButtonBg actionButtonColor--text',
         appendArrowIcon ? 'appendArrowIcon': ''
      ]"
      :height="height"
      :ripple="false"
      depressed
    >
      <span class="font-normal">
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'ActionButton',
  inheritAttrs: false,
  props: {
    appendArrowIcon: {
      type: Boolean,
      default: false
    },
    height: {
      type: String | Number,
      default: 48
    },
    hoverBtn: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    hoverBtn: {
      handler(hover) {
        this.$emit('isHover', hover)
      }
    }
  }
}
</script>

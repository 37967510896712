import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getLogin = formData => axios.post('auth/login', formData)
const getRegister = formData => axios.post('auth/register', formData)
const getLogout = () => axios.post('auth/logout')
const getLogoutAll = () => axios.post('auth/logout-all')
const checkUserAccess = () => axios.post('auth/check')
const getRoles = () => axios.get('roles')
const checkUserSubAccount = accountId => axios.get(`accounts/${accountId}`)
const getPlatforms = () => axios.get(`${getAccountId()}/pbxs`)
const sendEmailForResetPassword = formData => axios.post('auth/password', formData)
const checkResetToken = formData => axios.post('auth/validate-password-reset', formData)
const checkRegisterToken = token => axios.post(`auth/register/${token}`)
const sendNewPassword = formData => axios.post('auth/reset', formData)
const attachPbx = formData => axios.post(`${getAccountId()}/platform/own`, formData)

const getAuthMethods = () => axios.get(`${getAccountId()}/2fa/methods`)
const sendEnableAuthMethod = methodId => axios.get(`${getAccountId()}/2fa/methods/${methodId}/enable`)
const sendEnableVerificationCode = ({ methodId, formData }) => axios.post(`${getAccountId()}/2fa/methods/${methodId}`, formData)
const sendDisableVerificationCode = ({ methodId, params }) => axios.delete(`${getAccountId()}/2fa/methods/${methodId}`, { params })

const getRecoveryCodes = () => axios.get(`${getAccountId()}/2fa/recovery-codes`)
const generateRecoveryCodes = () => axios.post(`${getAccountId()}/2fa/recovery-codes`)


export {
  getLogin, getRegister, getLogout, getLogoutAll, checkUserAccess, getRoles, checkUserSubAccount, getPlatforms, sendEmailForResetPassword, checkResetToken, checkRegisterToken, sendNewPassword, attachPbx, getAuthMethods, sendEnableAuthMethod, sendEnableVerificationCode, sendDisableVerificationCode, getRecoveryCodes, generateRecoveryCodes
}
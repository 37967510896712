<template>
  <v-btn-toggle
    v-model="current"
    v-bind="$attrs"
    v-on="$listeners"
    class="switch-theme"
    borderless
    mandatory
    outlined
    active-class="switchThemeBg"
  >
    <v-btn
      class="rounded"
      :ripple="false"
      height="36"
      outlined
      :value="0"
    >
      <span
        class="font-normal"
        :class="current ? 'switchThemeColor--text' : 'switchThemeColorActive--text'"
      >Light</span>
    </v-btn>
    <v-btn
      class="rounded"
      :ripple="false"
      height="36"
      outlined
      :value="1"
    >
      <span
        class="font-normal"
        :class="current ? 'switchThemeColorActive--text' : 'switchThemeColor--text'"
      >Dark</span>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'SwitchThemeButton',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      default: 1
    }
  },
  computed: {
    current: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>

<style lang="scss">
.v-btn-toggle.switch-theme {
  border: 1px solid var(--v-switchThemeBorder-base);
  border-radius: 8px;
}
</style>
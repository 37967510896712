<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7274 7.2729V10.9093C12.7274 11.9134 11.9134 12.7274 10.9093 12.7274H7.2729M12.7274 7.2729H9.09109C8.08693 7.2729 7.2729 8.08693 7.2729 9.09109V12.7274M12.7274 7.2729H16.3638C17.368 7.2729 18.182 8.08693 18.182 9.09109V16.3638C18.182 17.368 17.368 18.182 16.3638 18.182H9.09109C8.08693 18.182 7.2729 17.368 7.2729 16.3638V12.7274M12.7274 7.2729V3.63654C12.7274 2.63239 11.9134 1.81836 10.9093 1.81836H3.63654C2.63239 1.81836 1.81836 2.63239 1.81836 3.63654V10.9093C1.81836 11.9134 2.63239 12.7274 3.63654 12.7274H7.2729" stroke="#1A1C1D" stroke-width="2"/>
  </svg>
</template>
<script>
export default {
  name: 'IntegrationsIcon',
  inheritAttrs: false
}
</script>

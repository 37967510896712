<template>
  <div class="main-wrapper is-hidden" :class="{'is-hidden': isFixed}">
    <AdminLeftDrawer @changeMini="changeMini" :mainDrawer.sync="drawer" />
    
    <AdminAppBar :mini="mini" :mainDrawer.sync="drawer" />
    
    <v-main class="mainLayoutBg">
      <router-view :key="$route.fullPath" />
    </v-main>
  </div>
</template>

<script>
import AdminLeftDrawer from '@/components/admin/AdminLeftDrawer'
import AdminAppBar from '@/components/admin/AdminAppBar'

import { mapActions } from 'vuex'
import { Types as indexTypes } from '@/store'

export default {
  name: 'AdminLayout',
  components: { AdminLeftDrawer, AdminAppBar },
  data: () => ({
    mini: true,
    drawer: true,
  }),
  computed: {
    isFixed() {
      return this.$vuetify.breakpoint.lgAndDown
    },
  },
  mounted() {
    this.getTimezones()
  },
  methods: {
    ...mapActions({
      getTimezones: indexTypes.actions.GET_TIMEZONES,
    }),
    changeMini(value) {
      this.mini = value
    },
  },
}
</script>

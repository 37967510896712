<template>
  <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.7 15H2.8C2.32261 15 1.86477 14.8043 1.52721 14.4561C1.18964 14.1078 1 13.6354 1 13.1429V3.85714C1 3.3646 1.18964 2.89223 1.52721 2.54394C1.86477 2.19566 2.32261 2 2.8 2H17.2C17.6774 2 18.1352 2.19566 18.4728 2.54394C18.8104 2.89223 19 3.3646 19 3.85714V13.1429C19 13.6354 18.8104 14.1078 18.4728 14.4561C18.1352 14.8043 17.6774 15 17.2 15H16.3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 6V9.66667" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 13H10.01" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 17L6 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'SystemIcon',
    inheritAttrs: false,
  }
</script>

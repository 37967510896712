<template>
  <v-hover #default="{ hover }">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.24722 13.2446L2.41759 11.9505L1.1235 16.7801" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7762 6.77982L17.6058 8.07392L18.8999 3.24429" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.8082 6.95232C16.2665 5.41906 15.2412 4.10339 13.8867 3.20357C12.5322 2.30375 10.922 1.86853 9.29859 1.9635C7.67522 2.05847 6.1267 2.67848 4.88637 3.7301C3.64603 4.78172 2.78109 6.20798 2.42188 7.79395" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.2152 13.0721C3.75689 14.6054 4.78224 15.921 6.13674 16.8208C7.49123 17.7207 9.10149 18.1559 10.7249 18.0609C12.3482 17.9659 13.8967 17.3459 15.1371 16.2943C16.3774 15.2427 17.2423 13.8164 17.6016 12.2305" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'Link-both-icon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

<template>
  <v-autocomplete
    class="select-accounts font-large-title"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="currentValue"
    :items="items"
    flat
    solo
    append-icon="mdi-chevron-down"
    height="46"
    :menu-props="{ nudgeTop: 2, contentClass: 'select-menu-account', maxWidth: 260 }"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
    
    <template #item="{ item, on }">
      <div v-on="on" class="account-name text-uppercase overflow-hidden font-weight-medium">{{ item.company }}</div>
      <v-spacer></v-spacer>
      <div>
        <TooltipAction bottom message="Copy account id">
          <template #activator>
            <v-icon size="16" @click.stop="copyId(item)">$copyIcon</v-icon>
          </template>
        </TooltipAction>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'
import { copyInStash } from '@/helper/app-helper'

export default {
  name: 'SelectAccounts',
  inheritAttrs: false,
  components: { TooltipAction },
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    currentValue: {
      get() {
        return typeof this.value === 'object' ? JSON.parse(JSON.stringify(this.value)) : this.value
      },
      set(value) {
        this.$emit('input', typeof this.value === 'object'
          ? JSON.parse(JSON.stringify(value))
          : value
        )
      }
    }
  },
  methods: {
    copyId(item) {
      copyInStash(item.slug)
      this.$notify({ type: 'info-bg', text: 'Copy successful' })
    }
  }
}
</script>

<style lang="scss">
.select-accounts {
  &.v-select--is-menu-active {
    & > .v-input__control {
      & > .v-input__slot {
        background: var(--v-selectAccountsBg-base)!important;
        border-radius: 5px 5px 0 0 ;
      }
    }
  }
  
  & > .v-input__control {
    & > .v-input__slot {
      border: 1px solid var(--v-selectAccountsBorder-base);
      padding-right: 0!important;
      
      & > .v-select__slot {
        height: 100%!important;
        
        & > input {
          text-transform: uppercase;
          letter-spacing: 0.08em !important;
          color: var(--v-selectAccountsTitle-base)
        }
        
        & > .v-input__append-inner {
          width: 46px!important;
          height: 100%!important;
          padding: 0!important;
          background: var(--v-selectAccountsBg-base)!important;
          border-radius: 0 5px 5px 0;
          
          &:hover {
            cursor: pointer;
          }
          
          & > .v-input__icon--append {
            margin: auto 0 !important;
            
            & > .v-icon {
              color: var(--v-selectAccountsIcon-base)!important;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div
    class="d-flex flex-column app-bar-notification"
    :class="checkMaxMd ? 'py-5 justify-space-between' : 'justify-center'"
    :style="styles"
    @click="onNotificationClick"
  >
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-icon class="mr-6" size="16" color="appBarNotificationTitle">$infoIcon</v-icon>

        <div class="font-large appBarNotificationTitle--text">
          DIALICS 2.0
        </div>
      </div>

      <AppBartNotificationDescription
        v-if="!checkMaxMd"
        :isPending="isPending"
      />

      <div
        class="d-flex align-center app-bar-notification__btn"
        @click="goToMigrate"
      >
        <div class="mr-2 font-normal appBarNotificationBtn--text">
          {{ isPending ? 'How to migrate data?' : 'Check migration status' }}
        </div>

        <v-icon size="24" color="appBarNotificationBtn">$arrowRightIcon</v-icon>
      </div>
    </div>

    <AppBartNotificationDescription
      v-if="checkMaxMd"
      :isPending="isPending"
    />
  </div>
</template>

<script>
import AppBartNotificationDescription
  from '@/components/navigations/AppBartNotification/AppBartNotificationDescription.vue'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'AppBartNotification',
  components: { AppBartNotificationDescription },
  props: {
    heightAppBar: {
      type: Number,
      default: 120
    },
    checkMaxMd: {
      type: Boolean,
      default: false
    },
    checkXl: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      isPending: authTypes.getters.IS_PENDING_MIGRATION,
    }),
    styles() {
      return {
        height: `${this.heightAppBar - 80}px`
      }
    }
  },
  methods: {
    goToMigrate() {
      this.$router.push({ name: this.isPending ? 'MigrationGuide' : 'MigrationProcess' })
    },
    onNotificationClick() {
      if (this.checkMaxMd) {
        this.goToMigrate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.app-bar-notification {
  padding: 0 30px 0 30px;
  background: var(--v-appBarNotification-base);

  @media screen and (max-width: 820px) {
    padding: 0 16px 0 16px;
  }

  &__btn {
    cursor: pointer;
  }
}
</style>

import { Types as authTypes } from '@/store/modules/auth'

export default function isMigrationGuide({ next, store }) {
  if (store.getters[authTypes.getters.IS_PENDING_MIGRATION]) {
    return next()
  } else {
    if (store.state.auth.role === 'Manager') {
      return next({ name: 'Dashboard' })
    }

    return next({ name: 'Reporting' })
  }
}
<template>
  <svg v-bind="$attrs" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3333 10.666C23.455 10.666 25.4898 11.5089 26.9901 13.0092C28.4904 14.5095 29.3333 16.5443 29.3333 18.666V27.9993H23.9999V18.666C23.9999 17.9588 23.719 17.2805 23.2189 16.7804C22.7188 16.2803 22.0405 15.9993 21.3333 15.9993C20.626 15.9993 19.9477 16.2803 19.4476 16.7804C18.9475 17.2805 18.6666 17.9588 18.6666 18.666V27.9993H13.3333V18.666C13.3333 16.5443 14.1761 14.5095 15.6764 13.0092C17.1767 11.5089 19.2115 10.666 21.3333 10.666Z" fill="#DADADA"/>
    <path d="M21.3333 10.666C23.455 10.666 25.4898 11.5089 26.9901 13.0092C28.4904 14.5095 29.3333 16.5443 29.3333 18.666V27.9993H23.9999V18.666C23.9999 17.9588 23.719 17.2805 23.2189 16.7804C22.7188 16.2803 22.0405 15.9993 21.3333 15.9993C20.626 15.9993 19.9477 16.2803 19.4476 16.7804C18.9475 17.2805 18.6666 17.9588 18.6666 18.666V27.9993H13.3333V18.666C13.3333 16.5443 14.1761 14.5095 15.6764 13.0092C17.1767 11.5089 19.2115 10.666 21.3333 10.666Z" fill="#DADADA"/>
    <path d="M2.66675 12H8.00008V28H2.66675V12Z" fill="#DADADA"/>
    <path d="M8.00008 5.33268C8.00008 6.80544 6.80617 7.99935 5.33341 7.99935C3.86066 7.99935 2.66675 6.80544 2.66675 5.33268C2.66675 3.85992 3.86066 2.66602 5.33341 2.66602C6.80617 2.66602 8.00008 3.85992 8.00008 5.33268Z" fill="#DADADA"/>
  </svg>
</template>

<script>
export default {
  name: 'LinkedinIcon',
  inheritAttrs: false,
}
</script>

import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getHeaderData = () => axios.get(`${getAccountId()}/calls/header`)
const getBalanceForPlatform = () => axios.get(`${getAccountId()}/settings/platform-balance`)

const getNotifications = () => axios.get(`${getAccountId()}/notification/all`)
const markReadNotifications = formData => axios.patch(`${getAccountId()}/notification/mark-read`, formData)

export {
  getHeaderData, getBalanceForPlatform, getNotifications, markReadNotifications
}
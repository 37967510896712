<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M6.36381 9.99965H13.6365M8.182 6.36328H5.45472C3.44641 6.36328 1.81836 7.99134 1.81836 9.99965C1.81836 12.008 3.44641 13.636 5.45472 13.636H8.182M11.8184 6.36328H14.5456C16.5539 6.36328 18.182 7.99134 18.182 9.99965C18.182 12.008 16.5539 13.636 14.5456 13.636H11.8184" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Link-both-icon',
  inheritAttrs: false,
}
</script>

<template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
    :open-on-hover="!touch"
    offset-x
    @click.stop=""
    content-class="drawer-mini-menu"
  >
    <template #activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
        :class="{ 'active-route': active }"
        inactive
        :ripple="false"
        @click.stop=""
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">{{ '$'+ item.icon + 'Icon' }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
    <div class="ml-15">
      <v-list class="leftDrawerHoverMenuBg">
        <v-list-item
          v-for="(child, index) in item.children"
          :key="index"
          :to="child.route"
          :ripple="false"
        >
          <v-list-item-title class="font-normal">
            {{ child.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>
<script>
export default {
  name: 'DrawerMenuMini',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    touch() {
      return this.$vuetify.breakpoint.width && matchMedia('(hover: none), (pointer: coarse)').matches
    }
  }
}
</script>

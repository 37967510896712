<template>
  <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" d="M6.23828 2.51172H13.7617L10.7383 8.48828H13.7617L8.48828 17.4883V10.7383H6.23828V2.51172Z"/>
  </svg>
</template>
<script>
  export default {
    name: 'ActionIcon',
    inheritAttrs: false,
  }
</script>

<template>
  <v-tooltip
    v-model="showToolTip"
    v-bind="$attrs"
    :max-width="maxWidth"
    :color="color"
    :z-index="zIndex"
    class="rounded textTooltip"
  >
    <template #activator="{ on }">
      <v-hover v-model="buttonHovering">
        <slot name="activator"></slot>
      </v-hover>
    </template>
    
    <span :class="messagesClass">{{ message }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipAction',
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'tooltipBg'
    },
    maxWidth: {
      type: String | Number,
      default: 'auto'
    },
    openDelay: {
      type: String | Number,
      default: 100
    },
    zIndex: {
      type: String | Number,
      default: 5
    },
    messagesClass: {
      type: String,
      default: 'font-normal tooltipColor--text'
    }
  },
  data: () => ({
    buttonHovering: false,
    showToolTip: false,
  }),
  methods: {
    visibleTooltip() {
      setTimeout(() => {
        if (this.buttonHovering) {
          this.showToolTip = true
        }
      }, this.openDelay)
    }
  },
  watch: {
    buttonHovering: {
      handler(value) {
        if (value) {
          this.visibleTooltip()
        } else {
          this.showToolTip = false
        }
      }
    },
  },
}
</script>

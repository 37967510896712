<template>
  <v-menu
    v-model="menu"
    content-class="accountsMenu"
    allow-overflow
    :close-on-content-click="false"
    min-width="320"
    offset-y
    nudge-bottom="17"
    nudge-left="250"
  >
    <template #activator="{ on }">
      <v-btn
        class="rounded violet hover-button mr-8 mr-sm-15"
        :ripple="false"
        height="50"
        min-width="52"
        v-on="on"
        depressed
      >
        <span class="font-large white--text">{{ getUserName[0] }}</span>
      </v-btn>
    </template>

    <v-list class="accountsList pa-0">
      <SelectAccounts
        class="accounts-container"
        :value="accountId"
        :items="accounts"
        item-value="slug"
        item-text="company"
        @change="checkUserSubAccount"
      />

      <div class="right-navigation-menu">
        <v-list-item
          v-if="isManager"
          class="nav-item mt-5 mx-2 pl-9"
          :ripple="false"
          :to="{ name: 'AccountDetails' }"
        >
          <v-list-item-icon>
            <v-icon color="drawerMenuIcon">$accountDetailsIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-normal navItem menuTitleColor--text">
              Account Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="isManager"
          class="rounded nav-item mx-2 mt-2"
          :ripple="false"
          :class="{ 'active-route': activeRoute(billingChildren) }"
        >
          <template #activator>
            <v-list-item class="pl-0">
              <v-list-item-icon>
                <v-icon color="drawerMenuIcon">$billingIcon</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-normal navItem menuTitleColor--text">
                  Billing
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="child in billingChildren"
            :key="child.title"
            :to="child.route"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title class="font-normal navChildItem menuTitleColor--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="isManager"
          class="nav-item mx-2 mt-2 pl-9"
          :ripple="false"
          :to="{ name: 'ManageUsers' }"
        >
          <v-list-item-icon>
            <v-icon color="drawerMenuIcon">$usersIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-normal navItem menuTitleColor--text">
              Users
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          class="rounded nav-item mx-2 mt-2"
          :ripple="false"
          :class="{ 'active-route': activeRoute(securityChildren) }"
        >
          <template #activator>
            <v-list-item class="pl-0">
              <v-list-item-icon>
                <v-icon color="drawerMenuIcon">$securityIcon</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-normal navItem menuTitleColor--text">
                  Security
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(child, index) in securityChildren"
            :key="child.title"
            :to="child.route"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title class="font-normal navChildItem menuTitleColor--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider v-if="isManager" class="mx-11 my-5" />

        <v-list-item
          v-if="isManager"
          class="nav-item mx-2 mt-2 pl-9"
          :ripple="false"
          :to="getToMigration"
        >
          <v-list-item-icon>
            <v-icon color="drawerMenuIcon">$rocketIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-normal navItem menuTitleColor--text">
              Migration
            </v-list-item-title>
          </v-list-item-content>

          <div class="menu-account__circle" />
        </v-list-item>

        <v-list-item
          class="nav-item mx-2 mt-2 pl-9"
          :ripple="false"
          @click="goToSupportLink"
        >
          <v-list-item-icon>
            <v-icon color="drawerMenuIcon">$supportIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-normal navItem menuTitleColor--text">
              Support
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="nav-item mx-2 mt-2 pl-9"
          :ripple="false"
          @click="logOut"
        >
          <v-list-item-icon>
            <v-icon color="drawerMenuIcon">$logoutIcon</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-normal navItem menuTitleColor--text">
              Log out
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-list-item class="themeAction">
        <SwitchThemeButton
          v-model="currentThemesOption"
          @input="switchThemes(currentThemesOption)"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import SwitchThemeButton from '@/components/buttons/SwitchThemeButton'
import SelectAccounts from '@/components/select/SelectAccounts'

import { mapState, mapGetters, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'
import { getAccountId } from '@/helper/auth-helper'

export default {
  name: 'MenuAccount',
  inheritAttrs: false,
  components: { SwitchThemeButton, SelectAccounts },
  data: () => ({
    menu: false,
    theme: 'dark',
    currentThemesOption: 1,
    billingChildren: [{
      title: 'Fill Balance',
      route: { name: 'FillBalance' },
    }, {
      title: 'Manage Cards',
      route: { name: 'ManageCards' },
    }, {
      title: 'Payment History',
      route: { name: 'PaymentHistory' },
    }],
  }),
  computed: {
    ...mapState({
      accounts: state => state.auth.accounts,
      currentAccountId: state => state.auth.accountId,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
      getUserName: authTypes.getters.GET_USER_NAME,
      isPending: authTypes.getters.IS_PENDING_MIGRATION,
    }),
    accountId: () => getAccountId(),
    route() {
      return this.$route
    },
    getToMigration() {
      if (this.isPending) {
       return { name: 'MigrationGuide' }
      }

      return { name: 'MigrationProcess' }
    },
    securityChildren() {
      if (this.isManager) {
        return [{
          title: 'Login Security',
          route: { name: 'LoginSecurity' },
        }, {
          title: 'Two-factor Auth',
          route: { name: 'TwoFactorAuth' },
        }, {
          title: 'Protection',
          route: { name: 'ProtectionCallLog' },
        }, {
          title: 'API Access Tokens',
          route: { name: 'ApiAccessTokens' },
        }]
      } else {
        return [{
          title: 'Login Security',
          route: { name: 'LoginSecurity' },
        }]
      }
    },
  },
  mounted() {
    this.currentThemesOption = +this.$vuetify.theme.isDark
  },
  methods: {
    ...mapActions({
      logOut: authTypes.actions.LOG_OUT,
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
    }),
    switchThemes(switches) {
      if (switches) {
        localStorage.setItem('theme', 'dark')
        this.theme = 'dark'
      } else {
        localStorage.setItem('theme', 'light')
        this.theme = 'light'
      }
      this.$vuetify.theme.dark = switches
    },
    activeRoute(children) {
      return children.some(child => {
        const name = child.title.split(' ')
        return `${this.$route.fullPath}`.indexOf(name[name.length -1].toLowerCase()) !== -1
      })
    },
    goToSupportLink() {
      window.open('https://dialics.com/support/', '_blank')
    },
  },
  watch: {
    route: {
      handler() {
        this.menu = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.menu-account {

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: var(--v-primary-base);
  }
}
</style>
<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" class="expend-down" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M11.8334 11.75L7.25002 16.3333L2.66669 11.75" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M17.3333 4.66663H10.9167C9.94421 4.66663 9.01157 5.05293 8.32394 5.74057C7.63631 6.4282 7.25 7.36083 7.25 8.33329C7.25 8.33329 7.25 13.2091 7.25 16.3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'CornerDown',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

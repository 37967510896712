<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini && checkLg"
    mini-variant-width="80"
    mobile-breakpoint="1280"
    width="300"
    color="leftDrawerBg"
    app
    :class="{ 'navigation-mini': mini && checkLg }"
  >
    <v-list-item
      link
      :ripple="false"
      dense
      @click="actionDrawer"
    >
      <v-list-item-icon v-if="mini && checkLg">
        <v-icon color="drawerMenuIcon">$menuIcon</v-icon>
      </v-list-item-icon>
      
      <v-list-item-icon v-else>
        <v-icon class="ml-8"  color="drawerMenuIcon">$backIcon</v-icon>
      </v-list-item-icon>
      
      <v-list-item-avatar class="rounded-0 mr-4">
        <v-img
          alt="logo"
          contain
          src="/svg/logo-icon.svg"
          max-width="30px"
        />
      </v-list-item-avatar>
      
      <v-list-item-title class="font-logo pt-1">Dialics</v-list-item-title>
    </v-list-item>
    <v-list class="pa-0" dense>
      <portal-target class="px-8" name="platformBalance"></portal-target>
      
      <v-list-item
        v-if="isManager"
        class="nav-item"
        :ripple="false"
        :to="{ name: 'Dashboard' }"
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">$dashboardIcon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        class="nav-item"
        :ripple="false"
        :to="{ name: 'Reporting' }"
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">$reportingIcon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            Reporting
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        v-if="role === 'Buyer'"
        class="nav-item"
        :ripple="false"
        :to="{ name: 'ManageDestinations' }"
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">$targetIcon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            Destinations
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-group
        v-for="item in menuItems"
        :key="item.title"
        v-if="item.children"
        v-model="item.active"
        class="rounded"
        :ripple="false"
        :class="{ 'active-route': activeRoute(item.children) }"
      >
        <template #activator>
          <DrawerMenuMini
            v-if="mini && checkLg"
            :item="item"
            :active="activeRoute(item.children)"
          />
          
          <v-list-item v-else>
            <v-list-item-icon>
              <v-icon color="drawerMenuIcon">{{'$'+ item.icon + 'Icon'}}</v-icon>
            </v-list-item-icon>
            
            <v-list-item-content>
              <v-list-item-title class="font-normal navItem menuTitleColor--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        
        <v-list-item
          v-if="!mini || !checkLg"
          v-for="child in item.children"
          :key="child.title"
          :to="child.route"
          :ripple="false"
        >
          <v-list-item-content>
            <v-list-item-title class="font-normal navChildItem menuTitleColor--text">
              {{ child.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      
      <v-list-item
        @click="logOut"
        class="nav-item"
        :ripple="false"
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">$turnOffIcon</v-icon>
        </v-list-item-icon>
        
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'
import DrawerMenuMini from '@/components/drawer/DrawerMenuMini'

export default {
  name: 'LeftDrawer',
  inheritAttrs: false,
  components: { DrawerMenuMini },
  props: {
    mainDrawer: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    mini: true,
    items: [
      {
        title: 'Campaigns',
        icon: 'campaign',
        active: false,
        children: [{
          title: 'Create Campaign',
          route: { name: 'CreateCampaign' },
        }, {
          title: 'Manage Campaigns',
          route: { name: 'ManageCampaigns' },
        }]
      }, {
        title: 'Vendors',
        icon: 'vendor',
        active: false,
        children: [{
          title: 'Create Vendor',
          route: { name: 'CreateVendor' },
        }, {
          title: 'Manage Vendors',
          route: { name: 'ManageVendors' },
        }]
      }, {
        title: 'Destinations',
        icon: 'target',
        active: false,
        children: [{
          title: 'Create Destination',
          route: { name: 'CreateDestination' },
        }, {
          title: 'Manage Destinations',
          route: { name: 'ManageDestinations' },
        }, {
          title: 'Create Group',
          route: { name: 'CreateGroup' },
        }, {
          title: 'Manage Groups',
          route: { name: 'ManageGroups' },
        }]
      }, {
        title: 'IVR',
        icon: 'ivr',
        active: false,
        children: [{
          title: 'Create Routing Plan',
          route: { name: 'CreateRoutingPlan' },
        }, {
          title: 'Manage Routing Plans',
          route: { name: 'ManageRoutingPlans' },
        }, {
          title: 'Create IVR',
          route: { name: 'CreateIvr' },
        }, {
          title: 'Manage IVR',
          route: { name: 'ManageIvr' },
        }, {
          title: 'Manage Medias',
          route: { name: 'ManageMedias' },
        }]
      }, {
        title: 'Buyer',
        icon: 'buyer',
        active: false,
        children: [{
          title: 'Create Buyer',
          route: { name: 'CreateBuyer' },
        }, {
          title: 'Manage Buyers',
          route: { name: 'ManageBuyer' },
        }]
      }, {
        title: 'Numbers',
        icon: 'number',
        active: false,
        children: [{
          title: 'Create Number',
          route: { name: 'CreateNumber' },
        }, {
          title: 'Manage Numbers',
          route: { name: 'ManageNumbers' },
        }, {
          title: 'Sync Numbers',
          route: { name: 'SyncNumbers' },
        }, {
          title: 'Create Pool',
          route: { name: 'CreatePool' },
        }, {
          title: 'Manage Pools',
          route: { name: 'ManagePools' },
        }, {
          title: 'Manage Blocked Numbers',
          route: { name: 'BlockedNumbers' },
        // }, {
        //   title: 'Numbers Fees',
        //   route: { name: 'NumbersFees' },
        }]
      }, {
        title: 'Traffic Sources',
        icon: 'integrations',
        active: false,
        children: [{
        //   title: 'Create URL Params',
        //   route: { name: 'CreateUrlParams' },
        // }, {
        //   title: 'Manage URL Params',
        //   route: { name: 'ManageUrlParams' },
        // }, {
        //   title: 'Create Pixel',
        //   route: { name: 'CreatePixel' },
        // }, {
        //   title: 'Manage Pixels',
        //   route: { name: 'ManagePixels' },
        // }, {
          title: 'Add Traffic Source',
          route: { name: 'AddTrafficSource' },
        }, {
          title: 'Manage Traffic Sources',
          route: { name: 'ManageTrafficSources' },
        }]
      }
    ],
    itemsGuest: [
      {
        title: 'Campaigns',
        icon: 'campaign',
        active: false,
        children: [{
          title: 'Create Campaign',
          route: { name: 'CreateCampaign' },
        }, {
          title: 'Manage Campaigns',
          route: { name: 'ManageCampaigns' },
        }]
      }, {
        title: 'Vendors',
        icon: 'vendor',
        active: false,
        children: [{
          title: 'Create Vendor',
          route: { name: 'CreateVendor' },
        }, {
          title: 'Manage Vendors',
          route: { name: 'ManageVendors' },
        }]
      }, {
        title: 'Destinations',
        icon: 'target',
        active: false,
        children: [{
          title: 'Create Destination',
          route: { name: 'CreateDestination' },
        }, {
          title: 'Manage Destinations',
          route: { name: 'ManageDestinations' },
        }, {
          title: 'Create Group',
          route: { name: 'CreateGroup' },
        }, {
          title: 'Manage Groups',
          route: { name: 'ManageGroups' },
        }]
      }, {
        title: 'IVR',
        icon: 'ivr',
        active: false,
        children: [{
          title: 'Create Routing Plan',
          route: { name: 'CreateRoutingPlan' },
        }, {
          title: 'Manage Routing Plans',
          route: { name: 'ManageRoutingPlans' },
        }, {
          title: 'Create IVR',
          route: { name: 'CreateIvr' },
        }, {
          title: 'Manage IVR',
          route: { name: 'ManageIvr' },
        }, {
          title: 'Manage Medias',
          route: { name: 'ManageMedias' },
        }]
      }, {
        title: 'Buyer',
        icon: 'buyer',
        active: false,
        children: [{
          title: 'Create Buyer',
          route: { name: 'CreateBuyer' },
        }, {
          title: 'Manage Buyers',
          route: { name: 'ManageBuyer' },
        }]
      }, {
        title: 'Numbers',
        icon: 'number',
        active: false,
        children: [{
          title: 'Create Number',
          route: { name: 'CreateNumber' },
        }, {
          title: 'Manage Numbers',
          route: { name: 'ManageNumbers' },
        }, {
          title: 'Create Pool',
          route: { name: 'CreatePool' },
        }, {
          title: 'Manage Pools',
          route: { name: 'ManagePools' },
        }, {
          title: 'Manage Blocked Numbers',
          route: { name: 'BlockedNumbers' },
        // }, {
        //   title: 'Numbers Fees',
        //   route: { name: 'NumbersFees' },
        }]
      }, {
        title: 'Traffic Sources',
        icon: 'integrations',
        active: false,
        children: [{
        //   title: 'Create URL Params',
        //   route: { name: 'CreateUrlParams' },
        // }, {
        //   title: 'Manage URL Params',
        //   route: { name: 'ManageUrlParams' },
        // }, {
        //   title: 'Create Pixel',
        //   route: { name: 'CreatePixel' },
        // }, {
        //   title: 'Manage Pixels',
        //   route: { name: 'ManagePixels' },
        // }, {
          title: 'Add Traffic Source',
          route: { name: 'AddTrafficSource' },
        }, {
          title: 'Manage Traffic Sources',
          route: { name: 'ManageTrafficSources' },
        }]
      }
    ],
    itemsVorVendor: [{
      title: 'Numbers',
      icon: 'number',
      active: false,
      children: [{
        title: 'Create Number',
        route: { name: 'CreateNumber' },
      }, {
        title: 'Manage Numbers',
        route: { name: 'ManageNumbers' },
      }]
    }]
  }),
  computed: {
    ...mapState({
      role: state => state.auth.role,
      permissions: state => state.auth.permissions,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
      getCurrentAccount: authTypes.getters.GET_CURRENT_ACCOUNT,
    }),
    checkLg() {
      return this.$vuetify.breakpoint.width > 1279
    },
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      },
    },
    menuItems() {
      if (this.isManager) {
        if (this.checkCurrentAccountId) {
          return this.items
        } else {
          return this.itemsGuest
        }
      }
      
      if (this.role === 'Vendor' && !!this.permissions?.create_numbers) {
        return this.itemsVorVendor
      }
      return []
    },
    checkCurrentAccountId() {
      return this.getCurrentAccount?.id === 3
    },
  },
  methods: {
    ...mapActions({
      logOut: authTypes.actions.LOG_OUT,
    }),
    activeRoute(children) {
      return children.some(child => {
        const name = child.title.split(' ')
        return `${this.$route.fullPath}`.indexOf(name[name.length -1].toLowerCase()) !== -1
      })
    },
    actionDrawer() {
      if (this.checkLg) {
        this.mini = !this.mini
      } else {
        this.drawer = !this.drawer
      }
    },
  },
  watch: {
    mini: {
      immediate: true,
      handler(value) {
        this.$emit('changeMini', value)
        if(value) {
          this.items = this.items.map(item => ({
            ...item,
            active: false
          }))
        }
      },
    },
  },
}
</script>

function getMigrationNotification() {
    const value = localStorage.getItem('migration_notification')
    return value ? Boolean(value) : false
}

function setMigrationNotification(token) {
    return localStorage.setItem('migration_notification', token)
}

export {
    getMigrationNotification,
    setMigrationNotification,
}

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M9.1665 4.16675L4.99984 7.50008H1.6665V12.5001H4.99984L9.1665 15.8334V4.16675Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M15.8919 4.1084C17.4541 5.67113 18.3317 7.79036 18.3317 10.0001C18.3317 12.2098 17.4541 14.329 15.8919 15.8917M12.9502 7.05007C13.7313 7.83143 14.1701 8.89105 14.1701 9.9959C14.1701 11.1007 13.7313 12.1604 12.9502 12.9417" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'VolumeOnIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].audioIcon
    }
  }
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66602 3.33301V8.33301H6.66602" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75843 12.5003C4.30012 14.0336 5.32547 15.3493 6.67997 16.2491C8.03446 17.1489 9.64472 17.5841 11.2681 17.4892C12.8914 17.3942 14.44 16.7742 15.6803 15.7226C16.9206 14.6709 17.7856 13.2447 18.1448 11.6587C18.504 10.0727 18.338 8.41299 17.6719 6.92956C17.0057 5.44613 15.8755 4.21939 14.4515 3.4342C13.0275 2.64902 11.3868 2.34792 9.7768 2.57629C8.16678 2.80466 6.67459 3.55012 5.5251 4.70034L1.66676 8.33367" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'ResetIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3077 7H8.69231C7.75767 7 7 7.75767 7 8.69231V16.3077C7 17.2423 7.75767 18 8.69231 18H16.3077C17.2423 18 18 17.2423 18 16.3077V8.69231C18 7.75767 17.2423 7 16.3077 7Z" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.30769 12H3.53846C3.13044 12 2.73912 11.8379 2.4506 11.5494C2.16209 11.2609 2 10.8696 2 10.4615V3.53846C2 3.13044 2.16209 2.73912 2.4506 2.4506C2.73912 2.16209 3.13044 2 3.53846 2H10.4615C10.8696 2 11.2609 2.16209 11.5494 2.4506C11.8379 2.73912 12 3.13044 12 3.53846V4.30769" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.0005 12.5005L12.5 12.5005L9.99951 12.5005" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5005 10.0005L12.5005 12.501L12.5005 15.0015" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'CloneIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>
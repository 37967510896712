<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7C11.3807 7 12.5 5.88071 12.5 4.5C12.5 3.11929 11.3807 2 10 2C8.61929 2 7.5 3.11929 7.5 4.5C7.5 5.88071 8.61929 7 10 7Z" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 18C6.38071 18 7.5 16.8807 7.5 15.5C7.5 14.1193 6.38071 13 5 13C3.61929 13 2.5 14.1193 2.5 15.5C2.5 16.8807 3.61929 18 5 18Z" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 18C16.3807 18 17.5 16.8807 17.5 15.5C17.5 14.1193 16.3807 13 15 13C13.6193 13 12.5 14.1193 12.5 15.5C12.5 16.8807 13.6193 18 15 18Z" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 8V9" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 10H13.3554C13.7916 10 14.2099 10.1756 14.5183 10.4882C14.8267 10.8007 15 11.2246 15 11.6667V12.5" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 10H6.64461C6.20843 10 5.79012 10.1756 5.4817 10.4882C5.17327 10.8007 5 11.2246 5 11.6667V13" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Ivr-icon',
  inheritAttrs: false,
}
</script>

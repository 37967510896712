<template>
  <div class="main-wrapper is-hidden" :class="{'is-hidden': isFixed}">
    <LeftDrawer @changeMini="changeMini" :mainDrawer.sync="drawer" />
    
    <AppBar :mini="mini" :mainDrawer.sync="drawer" />

    <v-main class="mainLayoutBg">
      <router-view :key="$route.fullPath" />
    </v-main>

    <DialogMigration/>
  </div>
</template>

<script>
import LeftDrawer from '@/components/navigations/LeftDrawer'
import AppBar from '@/components/navigations/AppBar'
import DialogMigration from '@/components/dialog/DialogMigration.vue'

import { mapState } from 'vuex'

export default {
  main: 'MainLayout',
  components: {DialogMigration, LeftDrawer, AppBar },
  data: () => ({
    mini: true,
    drawer: true,
  }),
  computed: {
    ...mapState({
      subscription: state => state.auth.subscription,
      redirectAccountBlocked: state => state.auth.redirectAccountBlocked,
    }),
    isFixed() {
      return this.$vuetify.breakpoint.lgAndDown
    },
  },
  methods: {
    changeMini(value) {
      this.mini = value
    },
  },
  watch: {
    subscription: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.$router.push({ name: 'Subscription' })
        }
      }
    },
    redirectAccountBlocked: {
      immediate: true,
      handler(redirect) {
        if (!!redirect) {
          this.$router.push({ name: 'AccountDisabled' })
        }
      },
    },
  },
}
</script>

<template>
  <v-app-bar
    app
    flat
    color="appBarBg"
    height="80px"
    class="elevation-0 border-bottom headerBar"
  >
    <IconOutlinedBtn
      class="d-lg-none ml-8 ml-sm-15 mr-10"
      width="50"
      minWidth="50"
      height="50"
      size="20"
      icon="$menuIcon"
      color="drawerMenuIcon"
      @click="actionDrawer"
    />
    
    <router-link :to="{ name: 'Rates' }">
      <div class="d-none d-md-flex ml-lg-15" v-if="mini || !drawer">
        <v-img
          alt="logo"
          contain
          src="/svg/logo-icon.svg"
          width="30px"
          class="mr-4"
        />
        <div class="font-logo">Dialics Admin</div>
      </div>
    </router-link>
    
    <portal-target name="pageTitle"></portal-target>
    
    <v-spacer/>
    <v-menu
      content-class="accountsMenu"
      allow-overflow
      :close-on-content-click="false"
      min-width="220"
      offset-y
      nudge-bottom="17"
      nudge-left="150"
    >
      <template #activator="{ on }">
        <v-btn
          class="rounded violet hover-button mr-8 mr-sm-15"
          :ripple="false"
          text
          outlined
          height="48"
          v-on="on"
        >
          <span class="font-large white--text">{{ getUserName[0] }}</span>
        </v-btn>
      </template>
      <v-list class="accountsList pa-0">
        <v-list-item class="themeAction">
          <SwitchThemeButton
            v-model="currentThemesOption"
            @input="switchThemes(currentThemesOption)"
          />
        </v-list-item>
        <v-list-item class="logout-action" :ripple="false" @click="logOut">
          <span class="font-normal grayLighten3--text">Logout</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import IconOutlinedBtn from '@/components/buttons/IconOutlinedBtn'
import SwitchThemeButton from '@/components/buttons/SwitchThemeButton'

import { mapGetters, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'AdminAppBar',
  inheritAttrs: false,
  components: { IconOutlinedBtn, SwitchThemeButton },
  props: {
    mini: {
      type: Boolean,
      default: true
    },
    mainDrawer: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    theme: 'dark',
    currentThemesOption: 1,
  }),
  computed: {
    ...mapGetters({
      getUserName: authTypes.getters.GET_USER_NAME,
    }),
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      }
    },
  },
  mounted() {
    this.currentThemesOption = +this.$vuetify.theme.isDark
  },
  methods: {
    ...mapActions({
      logOut: authTypes.actions.LOG_OUT,
    }),
    switchThemes(switches) {
      if (switches) {
        localStorage.setItem('theme', 'dark')
        this.theme = 'dark'
      } else {
        localStorage.setItem('theme', 'light')
        this.theme = 'light'
      }
      this.$vuetify.theme.dark = switches
    },
    actionDrawer() {
      this.drawer = !this.drawer
    },
  },
}
</script>

<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6667 6.66536V4.9987C11.6667 4.55667 11.4911 4.13275 11.1785 3.82019C10.8659 3.50763 10.442 3.33203 10 3.33203H4.16667C3.72464 3.33203 3.30072 3.50763 2.98816 3.82019C2.67559 4.13275 2.5 4.55667 2.5 4.9987V14.9987C2.5 15.4407 2.67559 15.8646 2.98816 16.1772C3.30072 16.4898 3.72464 16.6654 4.16667 16.6654H10C10.442 16.6654 10.8659 16.4898 11.1785 16.1772C11.4911 15.8646 11.6667 15.4407 11.6667 14.9987V13.332" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83594 10H17.5026M17.5026 10L15.0026 7.5M17.5026 10L15.0026 12.5" stroke="none" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Logout-icon',
  inheritAttrs: false,
}
</script>
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1606 9.29235C12.7452 7.63392 12.3917 5.70361 11.0595 4.37142C9.55064 2.86252 7.26689 2.60424 5.48611 3.56939L8.68064 6.76392C10 8 8 10 6.81936 8.73608L3.56939 5.48611C2.60424 7.28048 2.86252 9.55064 4.37142 11.0595C5.70361 12.3917 7.63392 12.7452 9.29235 12.1606L13.9278 16.7961C14.1997 17.068 14.6211 17.068 14.893 16.7961L15.8445 15.8445L16.7961 14.893C17.068 14.6211 17.068 14.1997 16.7961 13.9278L12.1606 9.29235Z" fill="#6F4FCC"/>
  </svg>
</template>

<script>
export default {
  name: 'Wrench-icon',
  inheritAttrs: false,
}
</script>
<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M8.182 1.81836H1.81836V8.182H8.182V1.81836Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M18.182 1.81836H11.8184V10.9093H18.182V1.81836Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M18.182 14.5459H11.8184V18.1823H18.182V14.5459Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M8.182 11.8184H1.81836V18.182H8.182V11.8184Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'DashboardIcon',
  inheritAttrs: false,
}
</script>

<template>
  <v-app-bar
    app
    flat
    color="appBarBg"
    :height="`${heightAppBar}px`"
    class="elevation-0 border-bottom headerBar"
  >
    <div class="width--full">
      <div class="py-8 d-flex">
        <IconOutlinedBtn
          class="d-lg-none ml-8 ml-sm-15 mr-10"
          width="50"
          minWidth="50"
          height="50"
          size="20"
          icon="$menuIcon"
          color="drawerMenuIcon"
          @click="actionDrawer"
        />

        <router-link
          v-if="mini || !drawer"
          class="d-none d-md-block ml-lg-15"
          :to="{ name: 'Reporting' }"
        >
          <div class="d-flex align-center">
            <v-img
              alt="logo"
              src="/svg/logo-icon.svg"
              width="30px"
              class="mr-4"
            />
            <div class="font-logo">Dialics</div>
          </div>
        </router-link>

        <portal-target name="pageTitle" :class="{ 'font-page-header-small' : checkXXs }"></portal-target>

        <v-spacer/>

        <div class="d-sm-flex mr-10 mr-sm-25 align-center">
          <div class="mr-10" v-if="isManager">
            <portal class="d-flex" to="notifications" :disabled="checkSm">
              <MenuNotification />
            </portal>
          </div>

          <portal to="platformBalance" :disabled="checkMd" v-if="checkBalance && isManager">
            <div class="d-flex align-center balance-btn" @click="goToFillBalance">
              <v-icon :class="checkMd ? 'mr-4' : 'mr-13 ml-2'" size="24">$balanceIcon</v-icon>

              <div :class="{ 'd-flex': checkMd }">
                <v-toolbar-title
                  class="d-flex"
                  :class="{ 'mr-12': checkMd && checkCurrentAccountId }"
                >
                  <div class="font-medium-base">Balance</div>

                  <div class="balance-count pl-4 font-medium-base" :class="{ 'pl-10': !checkMd }">{{ currency(balance) }}</div>
                </v-toolbar-title>

                <v-toolbar-title
                  class="d-flex mb-2 mb-sm-0"
                  v-if="checkCurrentAccountId"
                >
                  <div class="font-medium-base">Pbx</div>

                  <div class="balance-count pl-4 font-medium-base" :class="{ 'pl-10': !checkMd }">{{ currency(pbxBalance) }}</div>
                </v-toolbar-title>
              </div>
            </div>
          </portal>

          <v-toolbar-title class="font-large-title headerActionColor--text mr-0 ml-sm-12 mr-sm-12 mb-2 mb-sm-0">
            Live<span class="appBarLive--text pl-2">{{ liveCalls }}</span>
          </v-toolbar-title>

          <v-toolbar-title class="font-large-title headerActionColor--text ml-sm-12">
            Total<span class="violet--text pl-2">{{ totalCalls }}</span>
          </v-toolbar-title>
        </div>

        <MenuAccount />
      </div>

      <AppBartNotification
        v-if="!isMigration && isManager"
        :heightAppBar="heightAppBar"
        :checkMaxMd="checkMaxMd"
        :checkXl="checkXl"
      />
    </div>
  </v-app-bar>
</template>

<script>
import IconOutlinedBtn from '@/components/buttons/IconOutlinedBtn'
import MenuNotification from '@/components/menu/MenuNotification'
import MenuAccount from '@/components/menu/MenuAccount'
import AppBartNotification from '@/components/navigations/AppBartNotification/AppBartNotification.vue'

import { mapState, mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'
import { getAccountId } from '@/helper/auth-helper'
import { getHeaderData, getBalanceForPlatform } from '@/api/header-routes'

import currency from '@/mixins/currency'

export default {
  name: 'AppBar',
  inheritAttrs: false,
  components: { AppBartNotification, IconOutlinedBtn, MenuNotification, MenuAccount },
  mixins: [ currency ],
  props: {
    mini: {
      type: Boolean,
      default: true
    },
    mainDrawer: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    theme: 'dark',
    liveInterval: null,
    loadBalanceInterval: null,
    currentThemesOption: 1,
    liveCalls: 0,
    totalCalls: 0,
    balance: 0,
    pbxBalance: 0,
  }),
  computed: {
    ...mapState({
      accounts: state => state.auth.accounts,
      currentAccountId: state => state.auth.accountId,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
      getCurrentAccount: authTypes.getters.GET_CURRENT_ACCOUNT,
    }),
    accountId: () => getAccountId(),
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      },
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 740
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 920
    },
    checkXXs() {
      return this.$vuetify.breakpoint.width < 480
    },
    checkMaxMd() {
      return this.$vuetify.breakpoint.width <= 820
    },
    checkXl() {
      return this.$vuetify.breakpoint.width < 1440
    },
    checkBalance() {
      return !isNaN(this.balance)
    },
    checkCurrentAccountId() {
      return this.getCurrentAccount?.id === 3
    },
    isMigration() {
      const { name } = this.$route

      return name === 'MigrationProcess' || name === 'MigrationGuide'
    },
    heightAppBar() {
      if (!this.isManager) {
        return 80
      }

      if (this.isMigration) {
        return 80
      }

      if (this.checkMaxMd) {
        return 174
      }

      if (this.checkXl) {
        return 130
      }

      return 120
    }
  },
  mounted() {
    this.setHeaderInterval()
  },
  methods: {
    async loadHeaderData() {
      const { success, payload } = await getHeaderData()
      
      if (success) {
        if (this.liveCalls !== payload.live) {
          this.liveCalls = payload.live
        }
        if (this.totalCalls !== payload.completed) {
          this.totalCalls = payload.completed
        }
      }
    },
    async loadBalanceForPlatform() {
      const { success, payload } = await getBalanceForPlatform()
      
      if (success) {
        this.balance = payload.account
        this.pbxBalance = payload.pbx
      }
    },
    setHeaderInterval() {
      clearInterval(this.liveInterval)
      clearInterval(this.loadBalanceInterval)
      
      setTimeout(() => {
        this.loadHeadersOptions()
      })
    },
    loadHeadersOptions() {
      this.loadHeaderData()

      this.liveInterval = setInterval(() => {
        this.loadHeaderData()
      }, 30000)

      if (this.isManager) {
        this.loadBalanceForPlatform()

        this.loadBalanceInterval = setInterval(() => {
          this.loadBalanceForPlatform()
        }, 60000)
      }
    },
    actionDrawer() {
      this.drawer = !this.drawer
    },
    goToFillBalance() {
      this.$router.push({ name: 'FillBalance' })
    },
  },
  watch: {
    currentAccountId: {
      handler(value) {
        if (value) {
          this.setHeaderInterval()
        } else {
          clearInterval(this.liveInterval)
          clearInterval(this.loadBalanceInterval)
        }
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.liveInterval)
    clearInterval(this.loadBalanceInterval)
  },
}
</script>

<template>
  <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5458 1.81836H5.4549C4.45075 1.81836 3.63672 2.55098 3.63672 3.45472V16.5456C3.63672 17.4494 4.45075 18.182 5.4549 18.182H14.5458C15.55 18.182 16.364 17.4494 16.364 16.5456V3.45472C16.364 2.55098 15.55 1.81836 14.5458 1.81836Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.004 15L9 15L11 15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'CallerIcon',
  inheritAttrs: false,
}
</script>

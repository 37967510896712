<template>
  <svg v-bind="$attrs" class="status-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2V2C2.20914 2 4 3.79086 4 6V14C4 16.2091 2.20914 18 0 18V18V2Z" fill="#C4C4C4"/>
  </svg>
</template>

<script>
export default {
  name: 'Status-icon',
  inheritAttrs: false,
}
</script>

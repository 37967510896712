import FileDownload from 'js-file-download'
import { addDays, intervalToDuration, getTime, formatDistanceToNowStrict } from 'date-fns'
import format from 'date-fns/format'
import { getAccountId } from './auth-helper'
import { formatToTimeZone, convertToLocalTime } from 'date-fns-timezone'
import { toDate } from 'date-fns-tz'
import { localStorage } from 'reactive-localstorage'

import store from '@/store'
import { Types as authTypes } from '@/store/modules/auth'
import { ACCOUNT_MIGRATION_DATE_END } from '@/constants/accountMigrationStatus'

const isoFormat = 'yyyy-MM-dd'
const notificationFormat = 'LLL dd, yyyy'

function defaultDate() {
  return format(new Date(), isoFormat)
}

function formatDuration(seconds) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  const str = `${duration.hours}:${duration.minutes}:${duration.seconds}`
  return str.split(':').map(i => {
    return i = `${i}`.length === 1 ? `0${i}` : i
  }).join(':')
}

function calculateDuration(createdAt, timeZoneName = 'UTC' ) {
  const timestampDate = getTime(new Date())
  const localCreatedDate = convertToLocalTime(createdAt, { timeZone: timeZoneName === 'utc' ? 'UTC' : timeZoneName })
  
  const timestampCreatedDate = getTime(new Date(localCreatedDate))
  return (timestampDate - timestampCreatedDate) / 1000
}

function formatCallLogDate(date) {
  if (!date) {
    return ''
  }
  return format(toDate(date), 'MMM d, h:mm:ss a')
}

function defaultDateByTzName(name, date = new Date()) {
  if (name) {
    return formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: name === 'utc' ? 'UTC' : name })
  }
  
  return defaultDate()
}

function defaultFormatDate(date) {
  return format(new Date(date), isoFormat)
}

function formatDate(date) {
  return format(new Date(date), notificationFormat)
}

function getOnPage() {
  return Number.parseInt(localStorage.getItem('on_page'))
}

function setOnPage(onPage) {
  return localStorage.setItem('on_page', onPage)
}

function downloadCSVFile(res) {
  let fileName = 'report.csv'
  if (res.headers['content-disposition']) {
    const positionStart = res.headers['content-disposition'].indexOf('filename=') + 9
    fileName = res.headers['content-disposition'].substr(positionStart).split(';')[0]
  }
  FileDownload(res.data, fileName)
}

function copyInStash(text) {
  const tempInput = document.createElement('input')
  tempInput.value = text
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
}

function getDateUTC(date) {
  const fullDate = new Date(date)
  return Date.UTC(
    fullDate.getFullYear(),
    fullDate.getMonth(),
    fullDate.getDate(),
    fullDate.getHours(),
    fullDate.getMinutes()
  )
}

function changeItemsAfterUpdate(items, updatedItem, searchId = 'slug') {
  const index = items.findIndex(item => item[searchId] === updatedItem[searchId])
  if (index !== null && index > -1) {
    items[index] = Object.assign({}, items[index], updatedItem)
  }
  return cloneArray(items)
}

function renewDate(lastCharge) {
  return format(addDays(new Date(lastCharge ? lastCharge : new Date()), 30), isoFormat)
}

function getNameById(array, id, field = 'name', method = 'find', searchId = 'id') {
  try {
    return method === 'find'
      ? array[method](item => item[searchId] === id)[field]
      : array[method](item => item[searchId] === id)
  } catch (e) {
    return ''
  }
}

function redirectToRegister() {
  return window.location.href = 'https://dialics.com/amember/signup'
  // return window.open('https://dialics.com/amember/signup', '_blank')
}

function getTotalPages(totalItems, limit) {
  return Math.ceil(totalItems / limit)
}

function getAllTimezonesFromLocalStorage() {
  const allTimezones = JSON.parse(localStorage.getItem('account_timezones'))
  
  return Array.isArray(allTimezones) ? allTimezones : []
}

function getCurrentTimezone() {
  const accountId = getAccountId()
  const allTimezones = getAllTimezonesFromLocalStorage()
  
  const currentTimezone = allTimezones.find(t => t['a_id'] === accountId)
  
  return !!currentTimezone ? currentTimezone['t_id'] : null
}

function setTimezoneInLocalStorage(timezoneId) {
  const accountId = getAccountId()
  const allTimezones = getAllTimezonesFromLocalStorage()
  
  const foundTimezoneIndex = allTimezones.findIndex(t => t['a_id'] === accountId)
  
  if (foundTimezoneIndex + 1) {
    allTimezones[foundTimezoneIndex]['t_id'] = timezoneId
  } else {
    allTimezones.push({ 'a_id': accountId, 't_id': timezoneId })
  }
  
  localStorage.setItem('account_timezones', JSON.stringify(allTimezones))
}

function copyObject(object) {
  if (!object && !Object.keys(object).length) {
    return {}
  }
  
  return JSON.parse(JSON.stringify(object))
}

function customSorting(a, b, desc, type) {
  switch (type) {
    case 'number':
      return _numericSorting(a, b, desc)
    case 'time':
      return _timeSorting(a, b, desc)
    case 'status':
      return _numericSorting(a, b, !desc)
    default:
      return _stringSorting(a, b, desc)
  }
}

function _stringSorting(a, b, desc) {
  return desc ? a.toLowerCase().localeCompare(b.toLowerCase()) : b.toLowerCase().localeCompare(a.toLowerCase())
}

function _numericSorting(a, b, desc) {
  return desc ? a - b : b - a
}

function _timeSorting(a, b, desc) {
  const timeA = _calcTime(a)
  const timeB = _calcTime(b)
  
  return desc ? timeA - timeB : timeB - timeA
}

function _calcTime(time = []) {
  const arr = time.split(':')
  
  return (arr[0] * 60 * 60) + (arr[1] * 60) + arr[2]
}

function checkAppVersion(headers) {
  if (!headers['front-end-version']) {
    return
  }
  
  const newVersion = headers['front-end-version']
  const currentVersion = '1.0.42'
  
  if (newVersion !== currentVersion) {
    setNeedReload()
  }
}

function setNeedReload() {
  localStorage.setItem('need_reload', 'true')
}

function reloadAllWindow() {
  localStorage.setItem('reload', 'true')
}

function reloadAllWindowAfterUserReloadPage() {
  if (localStorage.getItem('reload') !== 'true' && localStorage.getItem('need_reload') === 'true') {
    localStorage.setItem('reload', 'true')
  }
}

function removeReloadItems() {
  localStorage.removeItem('need_reload')
  localStorage.removeItem('reload')
}

function setLocalStorageWatch() {
  localStorage.on('change', (key, value) => {
    switch (key) {
      case 'need_reload': value === 'true' && store.commit(authTypes.mutations.APP_VERSION_CHANGED, true)
        break
      case 'reload': value === 'true' && hardReload()
        break
    }
  })
}

function hardReload() {
  location.reload(true)
}

function setReloadWatcher() {
  window.addEventListener('beforeunload', () => {
    reloadAllWindowAfterUserReloadPage()
  })
}

function parsePaginationData(pagination) {
  const simple = !pagination.hasOwnProperty('last_page')
  const lastPage = simple
    ? pagination['next_page_url']
      ? pagination['current_page'] + 1
      : pagination['current_page']
    : pagination['last_page']
  return {
    simple,
    page: pagination['current_page'],
    lastPage,
  }
}

function sendGaEvent(category, action = 'Action') {
  if (!!window.ga) {
    if (!window.tracker) {
      window.tracker = ga.getAll()[0]
    }
    tracker.send('event', category, action, {
      nonInteraction: true
    })
  }
}

function cloneArray(arr) {
  return JSON.parse(JSON.stringify(arr))
}

function objectComparison(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

function randomID(length = 16) {
  return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""))
}

function compareDifferenceStrings(str1, str2) {
  return `${str1}`.toLowerCase() !== `${str2}`.toLowerCase()
}

function dateFormatDistanceForSupport() {
  return formatDistanceToNowStrict(ACCOUNT_MIGRATION_DATE_END.dateThree, { unit: 'day'})
}

export {
  defaultFormatDate,
  reloadAllWindow,
  setReloadWatcher,
  formatDuration,
  calculateDuration,
  formatCallLogDate,
  removeReloadItems,
  setLocalStorageWatch,
  defaultDate,
  defaultDateByTzName,
  formatDate,
  downloadCSVFile,
  getOnPage,
  setOnPage,
  copyInStash,
  getDateUTC,
  changeItemsAfterUpdate,
  renewDate,
  getNameById,
  redirectToRegister,
  getTotalPages,
  getCurrentTimezone,
  setTimezoneInLocalStorage,
  copyObject,
  customSorting,
  checkAppVersion,
  sendGaEvent,
  parsePaginationData,
  cloneArray,
  objectComparison,
  randomID,
  compareDifferenceStrings,
  isoFormat,
  dateFormatDistanceForSupport
}
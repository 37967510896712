<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.30152 16.2044L5.30154 16.2044L5.29738 16.2013C4.33293 15.478 3.54843 14.5419 3.00496 13.4658C2.46227 12.3913 2.17455 11.2062 2.16409 10.0025C2.19183 7.93335 3.02611 5.95667 4.48944 4.49334C5.95249 3.03029 7.92869 2.19605 9.9974 2.16801C12.0661 2.19605 14.0423 3.03029 15.5054 4.49334C16.9687 5.95667 17.803 7.93334 17.8307 10.0025C17.8202 11.2062 17.5325 12.3913 16.9898 13.4658C16.4464 14.5419 15.6619 15.478 14.6974 16.2013L14.6974 16.2013L14.6933 16.2044C13.3638 17.2232 11.6581 17.8352 9.9974 17.8352C8.33672 17.8352 6.63094 17.2232 5.30152 16.2044ZM6.23391 15.6696L6.15663 16.0236L6.46885 16.2072C7.53831 16.8365 8.75657 17.1683 9.9974 17.1683C11.2382 17.1683 12.4565 16.8365 13.5259 16.2072L13.8382 16.0236L13.7609 15.6696C13.6149 15.0011 13.2447 14.4025 12.7118 13.9733C12.1789 13.544 11.5152 13.3098 10.8309 13.3096H10.8307H9.16406H9.16391C8.4796 13.3098 7.81592 13.544 7.28299 13.9733C6.75006 14.4025 6.37989 15.0011 6.23391 15.6696ZM14.2111 14.8943L14.4963 15.5758L15.023 15.0578C15.6943 14.3975 16.2289 13.6115 16.5963 12.7446C16.9636 11.8776 17.1566 10.9468 17.164 10.0053L17.1641 10.0053L17.164 9.99517C17.1408 8.1033 16.3789 6.29546 15.0411 4.9576C13.7032 3.61975 11.8954 2.85789 10.0035 2.83467L10.0035 2.83452L9.99126 2.83467C8.09939 2.85789 6.29155 3.61975 4.9537 4.9576C3.61584 6.29546 2.85398 8.1033 2.83077 9.99517L2.83066 9.99517L2.83074 10.0053C2.83823 10.9468 3.03117 11.8776 3.39854 12.7446C3.76591 13.6115 4.30051 14.3975 4.97179 15.0578L5.49848 15.5758L5.78365 14.8943C6.06258 14.2277 6.53229 13.6583 7.13377 13.2578C7.73524 12.8572 8.44167 12.6433 9.16431 12.643H10.8305C11.5531 12.6433 12.2595 12.8572 12.861 13.2578C13.4625 13.6583 13.9322 14.2277 14.2111 14.8943Z" stroke="none"/>
    <path d="M12.5013 8.335C12.5013 9.72123 11.3775 10.845 9.99125 10.845C8.60502 10.845 7.48125 9.72123 7.48125 8.335C7.48125 6.94877 8.60502 5.825 9.99125 5.825C11.3775 5.825 12.5013 6.94877 12.5013 8.335Z" stroke="none" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Account-details-icon',
  inheritAttrs: false,
}
</script>
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import DeleteIcon from '@/components/icons/Delete-icon'
import PencilIcon from '@/components/icons/Pencil-icon'
import PauseIcon from '@/components/icons/Pause-icon'
import PlayIcon from '@/components/icons/Play-icon'
import StopIcon from '@/components/icons/Stop-icon'
import StopRedIcon from '@/components/icons/Stop-red-icon'
import StopTableIcon from '@/components/icons/Stop-table-icon'
import SearchIcon from '@/components/icons/Search-icon'
import SupportIcon from '@/components/icons/Support-icon'
import UserPlusIcon from '@/components/icons/User-plus-icon'
import MenuIcon from '@/components/icons/Menu-icon'
import BackIcon from '@/components/icons/Back-icon'
import ReportingIcon from '@/components/icons/Reporting-icon'
import CampaignIcon from '@/components/icons/Campaign-icon'
import VendorIcon from '@/components/icons/Vendor-icon'
import TargetIcon from '@/components/icons/Target-icon'
import NumberIcon from '@/components/icons/Number-icon'
import SettingIcon from '@/components/icons/Setting-icon'
import ActionIcon from '@/components/icons/Action-icon'
import FilterIcon from '@/components/icons/Filter-icon'
import SortDownIcon from '@/components/icons/Sort-down-icon'
import PhoneIcon from '@/components/icons/Phone-icon'
import LinkIcon from '@/components/icons/Link-icon'
import LinkBothIcon from '@/components/icons/Link-both-icon'
import LinkOffIcon from '@/components/icons/Link-off-icon'
import IntegrationsIcon from '@/components/icons/Integrations-icon'
import BuyerIcon from '@/components/icons/Buyer-icon'
import ArrowDown from '@/components/icons/Arrow-down'
import ArrowUp from '@/components/icons/Arrow-up'
import FlashIcon from '@/components/icons/Flash-icon.vue'
import CornerDown from '@/components/icons/Corner-down'
import VolumeOnIcon from '@/components/icons/Volume-on-icon'
import VolumeOffIcon from '@/components/icons/Volume-off-icon'
import DownloadIcon from '@/components/icons/Download-icon'
import CloseIcon from '@/components/icons/Close-icon'
import TurnOffIcon from '@/components/icons/Turn-off-icon'
import AlarmIcon from '@/components/icons/Alarm-icon'
import DashboardIcon from '@/components/icons/Dashboard-icon'
import PrevIcon from '@/components/icons/Prev-icon'
import NextIcon from '@/components/icons/Next-icon'
import RefreshIcon from '@/components/icons/Refresh-icon'
import CopyIcon from '@/components/icons/Copy-icon'
import StatusIcon from '@/components/icons/Status-icon'
import ResendIcon from '@/components/icons/Resend-icon'
import ResetIcon from '@/components/icons/Reset-icon'
import CallerIcon from '@/components/icons/hangup/Caller-icon'
import CalleeIcon from '@/components/icons/hangup/Callee-icon'
import SystemIcon from '@/components/icons/hangup/System-icon'
import ManagerIcon from '@/components/icons/hangup/Manager-icon'
import LogoIcon from '@/components/icons/logo/Logo-icon'
import FacebookIcon from '@/components/icons/social/Facebook-icon'
import LinkedinIcon from '@/components/icons/social/Linkedin-icon'
import BlockIcon from '@/components/icons/Block-icon'
import DollarIcon from '@/components/icons/Dollar-icon'
import SyncIcon from '@/components/icons/Sync-icon'
import SyncTableIcon from '@/components/icons/Sync-table-icon'
import ArrowTopIcon from '@/components/icons/arrow/Arrow-top-icon'
import IvrIcon from '@/components/icons/Ivr-icon'
import UploadIcon from '@/components/icons/Upload-icon'
import PlusIcon from '@/components/icons/Plus-icon'
import BalanceIcon from '@/components/icons/Balance-icon'
import AccountDetailsIcon from '@/components/icons/Account-details-icon'
import BillingIcon from '@/components/icons/Billing-icon'
import UsersIcon from '@/components/icons/Users-icon'
import SecurityIcon from '@/components/icons/Security-icon'
import LogoutIcon from '@/components/icons/Logout-icon'
import ArrowDownStrong from '@/components/icons/Arrow-down-strong'
import BellIcon from '@/components/icons/Bell-icon'
import CircleIcon from '@/components/icons/Circle-icon'
import UploadBigIcon from '@/components/icons/Upload-big-icon'
import DescriptionIcon from '@/components/icons/Description-icon'
import WrenchIcon from '@/components/icons/Wrench-icon'
import StopCallIcon from '@/components/icons/Stop-call-icon'
import AddDocumentsIcon from '@/components/icons/Add-documents-icon'
import CloneIcon from '@/components/icons/Clone-icon'
import OnlyLogoIcon from '@/components/icons/logo/Only-logo-icon'
import DeleteBothIcon from '@/components/icons/Delete-both-icon'
import EyeLightIcon from '@/components/icons/Eye-light-icon'
import WarningIcon from '@/components/icons/Warning-icon'
import InfoIcon from '@/components/icons/Info-icon.vue'
import ArrowRightIcon from '@/components/icons/Arrow-right-icon.vue'
import RocketIcon from '@/components/icons/Rocket-icon.vue'
import AttentionIcon from '@/components/icons/Attention-icon.vue'
import OkIcon from '@/components/icons/Ok-icon.vue'
import CloudFailIcon from '@/components/icons/Cloud-fail-icon.vue'
import CloudSuccessIcon from '@/components/icons/Cloud-success-icon.vue'

Vue.use(Vuetify)

let theme = 'dark'
if (localStorage.getItem('theme')) {
  theme = localStorage.getItem('theme')
} else {
  localStorage.setItem('theme', 'dark')
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    global: {
      ripples: false
    },
    values: {
      deleteIcon: {
        component: DeleteIcon,
        props: {
          name: 'delete-icon'
        }
      },
      pencilIcon: {
        component: PencilIcon,
        props: {
          name: 'pencil-icon'
        }
      },
      pauseIcon: {
        component: PauseIcon,
        props: {
          name: 'pause-icon'
        }
      },
      playIcon: {
        component: PlayIcon,
        props: {
          name: 'play-icon'
        }
      },
      stopIcon: {
        component: StopIcon,
        props: {
          name: 'stop-icon'
        }
      },
      stopRedIcon: {
        component: StopRedIcon,
        props: {
          name: 'stop-red-icon'
        }
      },
      stopTableIcon: {
        component: StopTableIcon,
        props: {
          name: 'stop-table-icon'
        }
      },
      searchIcon: {
        component: SearchIcon,
        props: {
          name: 'search-icon'
        }
      },
      supportIcon: {
        component: SupportIcon,
        props: {
          name: 'support-icon'
        }
      },
      userPlusIcon: {
        component: UserPlusIcon,
        props: {
          name: 'user-plus-icon'
        }
      },
      menuIcon: {
        component: MenuIcon,
        props: {
          name: 'menu-icon'
        }
      },
      backIcon: {
        component: BackIcon,
        props: {
          name: 'back-icon'
        }
      },
      reportingIcon: {
        component: ReportingIcon,
        props: {
          name: 'reporting-icon'
        }
      },
      campaignIcon: {
        component: CampaignIcon,
        props: {
          name: 'campaign-icon'
        }
      },
      vendorIcon: {
        component: VendorIcon,
        props: {
          name: 'vendor-icon'
        }
      },
      targetIcon: {
        component: TargetIcon,
        props: {
          name: 'target-icon'
        }
      },
      numberIcon: {
        component: NumberIcon,
        props: {
          name: 'number-icon'
        }
      },
      settingIcon: {
        component: SettingIcon,
        props: {
          name: 'setting-icon'
        }
      },
      actionIcon: {
        component: ActionIcon,
        props: {
          name: 'action-icon'
        }
      },
      filterIcon: {
        component: FilterIcon,
        props: {
          name: 'filter-icon'
        }
      },
      sortDownIcon: {
        component: SortDownIcon,
        props: {
          name: 'sort-down-icon'
        }
      },
      phoneIcon: {
        component: PhoneIcon,
        props: {
          name: 'phone-icon'
        }
      },
      linkIcon: {
        component: LinkIcon,
        props: {
          name: 'link-icon'
        }
      },
      linkBothIcon: {
        component: LinkBothIcon,
        props: {
          name: 'link-both-icon'
        }
      },
      linkOffIcon: {
        component: LinkOffIcon,
        props: {
          name: 'link-off-icon'
        }
      },
      integrationsIcon: {
        component: IntegrationsIcon,
        props: {
          name: 'integrations-icon'
        }
      },
      buyerIcon: {
        component: BuyerIcon,
        props: {
          name: 'buyer-icon'
        }
      },
      arrowDown: {
        component: ArrowDown,
        props: {
          name: 'arrow-down'
        }
      },
      arrowUp: {
        component: ArrowUp,
        props: {
          name: 'arrow-up'
        }
      },
      flashIcon: {
        component: FlashIcon,
        props: {
          name: 'flash-icon'
        }
      },
      cornerDown: {
        component: CornerDown,
        props: {
          name: 'corner-icon'
        }
      },
      volumeOnIcon: {
        component: VolumeOnIcon,
        props: {
          name: 'volume-on-icon'
        }
      },
      volumeOffIcon: {
        component: VolumeOffIcon,
        props: {
          name: 'volume-off-icon'
        }
      },
      downloadIcon: {
        component: DownloadIcon,
        props: {
          name: 'download-icon'
        }
      },
      closeIcon: {
        component: CloseIcon,
        props: {
          name: 'close-icon'
        }
      },
      turnOffIcon: {
        component: TurnOffIcon,
        props: {
          name: 'turn-off-icon'
        }
      },
      alarmIcon: {
        component: AlarmIcon,
        props: {
          name: 'alarm-icon'
        }
      },
      dashboardIcon: {
        component: DashboardIcon,
        props: {
          name: 'dashboard-icon'
        }
      },
      prevIcon: {
        component: PrevIcon,
        props: {
          name: 'prev-icon'
        }
      },
      nextIcon: {
        component: NextIcon,
        props: {
          name: 'next-icon'
        }
      },
      refreshIcon: {
        component: RefreshIcon,
        props: {
          name: 'refresh-icon'
        }
      },
      copyIcon: {
        component: CopyIcon,
        props: {
          name: 'copy-icon'
        }
      },
      statusIcon: {
        component: StatusIcon,
        props: {
          name: 'status-icon'
        }
      },
      resendIcon: {
        component: ResendIcon,
        props: {
          name: 'resend-icon'
        }
      },
      resetIcon: {
        component: ResetIcon,
        props: {
          name: 'reset-icon'
        }
      },
      callerIcon: {
        component: CallerIcon,
        props: {
          name: 'caller-icon'
        }
      },
      calleeIcon: {
        component: CalleeIcon,
        props: {
          name: 'callee-icon'
        }
      },
      systemIcon: {
        component: SystemIcon,
        props: {
          name: 'system-icon'
        }
      },
      managerIcon: {
        component: ManagerIcon,
        props: {
          name: 'manager-icon'
        }
      },
      logoIcon: {
        component: LogoIcon,
        props: {
          name: 'logo-icon'
        }
      },
      facebookIcon : {
        component: FacebookIcon,
        props: {
          name: 'facebook-icon'
        }
      },
      linkedinkIcon: {
        component: LinkedinIcon,
        props: {
          name: 'linkedink-icon'
        }
      },
      blockIcon: {
        component: BlockIcon,
        props: {
          name: 'block-icon'
        }
      },
      dollarIcon: {
        component: DollarIcon,
        props: {
          name: 'dollar-icon'
        }
      },
      syncIcon: {
        component: SyncIcon,
        props: {
          name: 'sync-icon'
        }
      },
      syncTableIcon: {
        component: SyncTableIcon,
        props: {
          name: 'sync-table-icon'
        }
      },
      arrowTopIcon: {
        component: ArrowTopIcon,
        props: {
          name: 'arrow-top-icon'
        }
      },
      ivrIcon: {
        component: IvrIcon,
        props: {
          name: 'ivr-icon'
        }
      },
      uploadIcon: {
        component: UploadIcon,
        props: {
          name: 'upload-icon'
        }
      },
      plusIcon: {
        component: PlusIcon,
        props: {
          name: 'plus-icon'
        }
      },
      balanceIcon: {
        component: BalanceIcon,
        props: {
          name: 'balance-icon'
        }
      },
      accountDetailsIcon: {
        component: AccountDetailsIcon,
        props: {
          name: 'account-details-icon'
        }
      },
      billingIcon: {
        component: BillingIcon,
        props: {
          name: 'billing-icon'
        }
      },
      usersIcon: {
        component: UsersIcon,
        props: {
          name: 'users-icon'
        }
      },
      securityIcon: {
        component: SecurityIcon,
        props: {
          name: 'security-icon'
        }
      },
      logoutIcon: {
        component: LogoutIcon,
        props: {
          name: 'logout-icon'
        }
      },
      arrowDownStrong: {
        component: ArrowDownStrong,
        props: {
          name: 'arrow-down-strong-icon'
        }
      },
      bellIcon: {
        component: BellIcon,
        props: {
          name: 'bell-icon'
        }
      },
      circleIcon: {
        component: CircleIcon,
        props: {
          name: 'circle-icon'
        }
      },
      uploadBigIcon: {
        component: UploadBigIcon,
        props: {
          name: 'upload-big-icon'
        }
      },
      descriptionIcon: {
        component: DescriptionIcon,
        props: {
          name: 'description-icon'
        }
      },
      wrenchIcon: {
        component: WrenchIcon,
        props: {
          name: 'wrench-icon'
        }
      },
      stopCallIcon: {
        component: StopCallIcon,
        props: {
          name: 'stop-call-icon'
        }
      },
      addDocumentsIcon: {
        component: AddDocumentsIcon,
        props: {
          name: 'add-documents-icon'
        }
      },
      cloneIcon: {
        component: CloneIcon,
        props: {
          name: 'clone-icon'
        }
      },
      onlyLogoIcon: {
        component: OnlyLogoIcon,
        props: {
          name: 'only-logo-icon'
        }
      },
      deleteBothIcon: {
        component: DeleteBothIcon,
        props: {
          name: 'delete-both-icon'
        }
      },
      eyeLightIcon: {
        component: EyeLightIcon,
        props: {
          name: 'eye-light-icon'
        }
      },
      warningIcon: {
        component: WarningIcon,
        props: {
          name: 'warning-icon'
        }
      },
      infoIcon: {
        component: InfoIcon,
        props: {
          name: 'info-icon'
        }
      },
      arrowRightIcon: {
        component: ArrowRightIcon,
        props: {
          name: 'arrow-right-icon'
        }
      },
      rocketIcon: {
        component: RocketIcon,
        props: {
          name: 'rocket-icon'
        }
      },
      attentionIcon: {
        component: AttentionIcon,
        props: {
          name: 'attention-icon'
        }
      },
      okIcon: {
        component: OkIcon,
        props: {
          name: 'ok-icon'
        }
      },
      cloudFailIcon: {
        component: CloudFailIcon,
        props: {
          name: 'cloud-fail-icon'
        }
      },
      cloudSuccessIcon: {
        component: CloudSuccessIcon,
        props: {
          name: 'cloud-success-icon'
        }
      }
    },
  },
  theme: {
    dark: theme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#6F4FCC',
        lightPrimary: '#F4F0F9',
        yellow: '#FFDA1A',
        yellowLight: '#9CE500',
        pink: '#F06269',
        violet: '#6F4FCC',
        grayLighten3: '#A0A0A0',

        emptyLayoutBg: '#6F4FCC',
        loginTitle: '#FFFFFF',
        loginColor: '#F4F0F9',
        loginNameColor: '#FFFFFF',
        linkColor: '#452d8c',
        loginFont: '#F4F0F9',
        loginInputBg: '#8363E0',
        loginLinkColor: '#C9A9F5',
        loginLinkColorHover: '#F4F0F9',
        linkColorHover: '#585858',
        loginFieldBorder: '#C9A9F5',
        mainBorder: '#6F4FCC',

        inviteTitle: '#FFFFFF',
        inviteColor: '#F4F0F9',
        inviteFont: '#F4F0F9',
        inviteInputBg: '#8363E0',
        inviteFieldBorder: '#C9A9F5',

        dashboardRevenue: '#9CE500',
        dashboardIncoming: '#41AFFF',
        dashboardConverted: '#6F4FCC',
        dashboardNoAnswer: '#F06269',
        dashboardPayout: '#B3A0CA',
        dashboardACL: '#A0A0A0',
        statsNameColor: '#A0A0A0',
        statsTotalColor: '#222526',

        appBarText: '#222526',
        appBarBg: '#FFFFFF',
        appBarLive: '#6F4FCC',
        appBarBalance: '#A0A0A0',
        headerActionColor: '#A0A0A0',

        switchThemeBorder: '#F4F0F9',
        switchThemeColorActive: '#6F4FCC',
        switchThemeColor: '#A0A0A0',
        switchThemeBg: '#F4F0F9',
        logoutContainer: '#FAFAFA',

        leftDrawerBg: '#FFFFFF',
        leftDrawerHoverMenuBg: '#FFFFFF',
        drawerChevronIconColor: '#DADADA',
        drawerIconColor: '#DADADA',
        drawerIconColorHover: '#585858',
        drawerIconBorder: '#F4F0F9',
        drawerIconBorderOpen: '#B29FE3',
        drawerItemHover: '#F6F6F4',
        drawerItemActiveBg: '#F4F0F9',
        drawerTextHover: '#342D48',
        iconOnTable: '#6C6C6C',
        iconGray: '#DADADA',
        iconHover: '#222526',
        drawerMenuIcon: '#585858',
        refreshIcon: '#A0A0A0',
        actionIcon: '#A0A0A0',
        audioIcon: '#DADADA',
        searchGray: '#DADADA',
        sortIcon: '#DADADA',
        sortIconActive: '#9E9E9E',
        actionIconHover: '#6F4FCC',
        menuTitleColor: '#6C6C6C',
        menuTitleColorHover: '#342D48',
        menuHeaderColor: '#A0A0A0',
        menuHeaderColorHover: '#6C6C6C',

        scrollBg: '#FFFFFF',
        scrollColor: '#585858',

        selectAccountsBg: '#FAFAFA',
        selectAccountsBorder: '#DADADA',
        selectAccountsTitle: '#222526',
        selectAccountsColor: '#A0A0A0',
        selectAccountsColorHover: '#222526',
        selectAccountsIcon: '#585858',
        selectAccountsIconHover: '#585858',

        mainLayoutBg: '#FAFAFA',
        cardBg: '#FFFFFF',
        tagMenuBg: '#F4F0F9',
        tagMenuColor: '#585858',
        tabBg: '#FFFFFF',
        tabIconColor: '#A0A0A0',
        tabIconDisabledColor: '#DADADA',
        pageTitleColor: '#A0A0A0',

        actionButtonBg: '#F4F0F9',
        actionButtonColor: '#6F4FCC',
        actionButtonHoverBg: '#6F4FCC',
        actionButtonHoverColor: '#FFFFFF',
        actionButtonForDarkBg: '#5B40AA',
        actionButtonForDarkColor: '#C9A9F5',
        actionButtonForDarkHoverBg: '#FFDA1A',
        actionButtonForDarkHoverColor: '#342D48',
        cancelButtonColor: '#9E9E9E',
        cancelButtonHoverColor: '#1A1C1D',
        primaryButtonBg: '#6F4FCC',
        buttonColor: '#222526',
        buttonBorder: '#DADADA',
        buttonHover: '#F0F0F0',
        buttonLoader: '#6F4FCC',

        cardBorder: '#DADADA',
        dashboardCardBorder: '#DADADA',
        infoCardBorder: '#EEEEEE',

        nameField: '#6C6C6C',
        nameFieldBack: '#7d7d7d',
        descriptionTitle: '#222526',
        descriptionText: '#A0A0A0',
        inputFocus: '#FAFAFA',
        loginInputColor: '#FAFAFA',
        inputColor: '#6C6C6C',
        inputExpandedPanelColor: '#FAFAFA',
        inputColorHover: '#383839',
        inputColorChevron: '#A0A0A0',
        inputColorChevronHover: '#DADADA',
        selectArrow: '#DADADA',
        selectColor: '#DADADA',
        selectOnPageChevron: '#DADADA',
        selectChevron: '#DADADA',
        inputNumberDefaultUnderscore: '#DADADA',

        switchBg: '#EEEEEE',
        switchBorder: '#DADADA',
        switchActiveBg: '#F4F0F9',
        switchActiveBorder: '#6F4FCC',
        switchActiveGreenBg: '#E7F3CC',
        switchActiveGreenBorder: '#9CE500',

        toggleButtonBg: '#F4F0F9',
        toggleButtonActiveBg: '#6F4FCC',
        toggleButtonColor: '#342D48',
        toggleButtonActiveColor: '#EEEEEE',
        toggleButtonHoverColor: '#6F4FCC',

        switchButton: '#C9A9F5',
        switchButtonActive: '#6F4FCC',
        switchButtonColor: '#F4F0F9',
        switchButtonHoverColor: '#F4F0F9',
        
        outlinedButton: '#C9A9F5',
        creditCardBg: '#8363E0',

        copyBtnBg: '#F4F0F9',
        copyBtnBgHover: '#6F4FCC',
        copyBtnColor: '#342D48',
        copyBtnColorHover: '#F4F0F9',

        expandPanelBg: '#6F4FCC',
        cardChildBg: '#FFFFFF',

        tableBg: '#FFFFFF',
        tableBgEven: '#F9F9F9',
        tableHeaderText: '#222526',
        tableBodyText: '#6C6C6C',

        paginationBg: '#FFFFFF',
        paginationColor: '#6C6C6C',
        paginationBorder: '#DADADA',
        paginationActiveColor: '#A0A0A0',
        paginationArrowColor: '#383839',

        completeStatus: '#9CE500',
        incompleteStatus: '#F06269',

        toasterInfo: '#342D48',
        toasterSuccess: '#9CE500',
        toasterError: '#F06269',

        tooltipBg: '#C79BFF',
        tooltipColor: '#F4F0F9',
        tooltipIcon: '#9E9E9E',

        pieChartBorder: '#FFFFFF',
        cardChartBg: '#FFFFFF',
        chartTitle: '#A0A0A0',
        chartAxisTitle: '#DADADA',
        chartAxisLabels: '#DADADA',
        chartAxisXLabels: '#A0A0A0',
        chartLineColor: '#F4F0F9',
        chartHeaderColor: '#6C6C6C',
        chartLegendColor: '#A0A0A0',
        chartLegendHoverColor: '#585858',
        chartTextColor: '#585858',
        chartStackLabelColor: '#585858',
        chartBarLabelColorLight: '#EEEEEE',
        chartBarLabelColorDark: '#342D48',

        cardFilterBg: '#FFFFFF',
        overlayColor: '#DADADA',

        dateRangePickerBg: '#F9F9F9',
        dateRangePickerColor: '#A0A0A0',
        dateRangePickerActiveColor: '#222526',
        headerInputBg: '#FFFFFF',
        inputBorder: '#DADADA',
        inputBorderDisabled: '#EEEEEE',
        inputIconDisabled: '#A0A0A0',
        inputBorderHover: '#A0A0A0',

        timePickerBg: '#FFFFFF',
        timePickerTitle: '#6C6C6C',
        timePickerIcon: '#B7A7E5',
        timePickerIconHover: '#6F4FCC',
        timePickerBorder: '#A0A0A0',
        timePickerColor: '#A0A0A0',
        timePickerColorActive: '#1A1C1D',

        dialogTitle: '#222526',
        dialogConfirmTitle: '#A0A0A0',
        dialogConfirmSubTitle: '#222526',
        dialogConfirmQuestion: '#222526',
        dialogShowCodeTitle: '#6C6C6C',
        dialogShowCodeSubTitle : '#A0A0A0',
        dialogShowCodeColor: '#A0A0A0',
        dialogShowCodeBg: '#FAFAFA',
        dialogShowCodeAlarmBorder: '#DADADA',

        tabColor: '#A0A0A0',
        tabHoverColor: '#6C6C6C',

        autoRefreshBtnBg: '#FFFFFF',
        autoRefreshBtnColor: '#6C6C6C',
        autoRefreshBtnColorHover: '#383839',
        autoRefreshBtnActiveColor: '#6F4FCC',
        autoRefreshBorder: '#DADADA',
        autoRefreshBorderHover: '#A0A0A0',
        autoRefreshBorderActive: '#C9A9F5',
        autoRefreshBorderActiveHover: '#6F4FCC',

        filterBtnBg: '#FFFFFF',
        filterBtnColor: '#6C6C6C',
        filterBtnLabelColor: '#A0A0A0',
        filterBtnColorHover: '#383839',
        filterBtnIconColor: '#6F4FCC',
        filterBtnIconColorHover: '#F06269',
        filterBtnBorder: '#DADADA',
        filterBtnBorderHover: '#A0A0A0',

        resetBtnBg: '#6F4FCC',
        resetBtnColor: '#F4F0F9',
        resetBtnColorHover: '#FFFFFF',

        cardTimePickerDay: '#585858',
        cardTimePickerCloseOpen: '#A0A0A0',
        cardTimePickerTime: '#6C6C6C',
        cardTimePickerBorder: '#CCA4FF',
        cardTimePickerDayOff: '#6C6C6C',
        cardTimePickerTimeOff: '#DADADA',
        cardTimePickerBorderOff: '#EEEEEE',

        pbxBg: '#6F4FCC',
        pbxInfoBorder: '#8363E0',
        pbxAlarmIcon: '#CCA4FF',
        pbxTextInfo: '#CCA4FF',
        pbxTextLink: '#FFDA1A',
        pbxTextTitle: '#FFFFFF',
        pbxTextSubTitle: '#E3CDFF',
        pbxFieldName: '#F4F0F9',
        pbxCancelBtnBorder: '#C9A9F5',
        pbxActionBtnBg: '#5B40AA',
        pbxActionBtnText: '#C9A9F5',
        pbxActionBtnTextHover: '#342D48',

        pendingColor: '#FFDA1A',
        acceptedColor: '#6F4FCC',

        inviteCardBg: '#6F4FCC',

        appBarNotification: '#F4F0F9',
        appBarNotificationText: '#585858',
        appBarNotificationTitle: '#222526',
        appBarNotificationBtn: '#9775FA',

        titleMigration: '#222526',
        descriptionMigration: '#A0A0A0',
        bgStepMigration: '#EEEEEE',
        bgStepContentMigration: '#FAFAFA',

        borderProcessMigration: '#DADADA',
        circleProcessMigration: '#A0A0A0',
        headerProcessMigration: '#F4F0F9',
        textProcessMigration: '#A0A0A0',
        infoProcessMigration: '#EEEEEE',
        infoTextProcessMigration: '#6C6C6C',

        borderGray: '#DADADA'
      },
      dark: {
        primary: '#6F4FCC',
        lightPrimary: '#F4F0F9',
        yellow: '#FFDA1A',
        yellowLight: '#9CE500',
        pink: '#F06269',
        violet: '#6F4FCC',
        grayLighten3: '#A0A0A0',

        emptyLayoutBg: '#101010',
        loginTitle: '#EEEEEE',
        loginColor: '#A0A0A0',
        loginNameColor: '#6F4FCC',
        linkColor: '#6F4FCC',
        loginFont: '#DADADA',
        loginInputBg: '#222526',
        loginLinkColor: '#6C6C6C',
        loginLinkColorHover: '#DADADA',
        linkColorHover: '#DADADA',
        loginFieldBorder: '#383839',
        mainBorder: '#383839',

        inviteTitle: '#EEEEEE',
        inviteColor: '#A0A0A0',
        inviteFont: '#DADADA',
        inviteInputBg: '#222526',
        inviteFieldBorder: '#DADADA',

        dashboardRevenue: '#9CE500',
        dashboardIncoming: '#41AFFF',
        dashboardConverted: '#6F4FCC',
        dashboardNoAnswer: '#F06269',
        dashboardPayout: '#B3A0CA',
        dashboardACL: '#A0A0A0',
        statsNameColor: '#A0A0A0',
        statsTotalColor: '#DADADA',

        appBarText: '#D1D1D7',
        appBarBg: '#1A1C1D',
        appBarLive: '#9CE500',
        appBarBalance: '#EEEEEE',
        headerActionColor: '#6C6C6C',

        switchThemeBorder: '#6F4FCC',
        switchThemeColorActive: '#FFFFFF',
        switchThemeColor: '#6F4FCC',
        switchThemeBg: '#6F4FCC',
        logoutContainer: '#1E2021',

        leftDrawerBg: '#1A1C1D',
        leftDrawerHoverMenuBg: '#222526',
        drawerChevronIconColor: '#6C6C6C',
        drawerIconColor: '#A0A0A0',
        drawerIconColorHover: '#DADADA',
        drawerIconBorder: '#383839',
        drawerIconBorderOpen: '#383839',
        drawerItemHover: '#F6F6F4',
        drawerItemActiveBg: '#222526',
        drawerTextHover: '#DADADA',
        iconOnTable: '#6C6C6C',
        iconGray: '#A0A0A0',
        iconHover: '#DADADA',
        drawerMenuIcon: '#A0A0A0',
        refreshIcon: '#A0A0A0',
        actionIcon: '#6C6C6C',
        audioIcon: '#DADADA',
        searchGray: '#9E9E9E',
        sortIcon: '#6C6C6C',
        sortIconActive: '#9E9E9E',
        actionIconHover: '#6F4FCC',
        menuTitleColor: '#6C6C6C',
        menuTitleColorHover: '#DADADA',
        menuHeaderColor: '#6C6C6C',
        menuHeaderColorHover: '#A0A0A0',

        scrollBg: '#1E2021',
        scrollColor: '#A0A0A0',

        selectAccountsBg: '#222526',
        selectAccountsBorder: '#383839',
        selectAccountsTitle: '#DADADA',
        selectAccountsColor: '#6C6C6C',
        selectAccountsColorHover: '#DADADA',
        selectAccountsIcon: '#A0A0A0',
        selectAccountsIconHover: '#DADADA',

        mainLayoutBg: '#1A1C1D',
        cardBg: '#1A1C1D',
        tagMenuBg: '#222526',
        tagMenuColor: '#A0A0A0',
        tabBg: '#1A1C1D',
        tabIconColor: '#6C6C6C',
        tabIconDisabledColor: '#383838',
        pageTitleColor: '#383838',

        actionButtonBg: '#342D48',
        actionButtonColor: '#EEEEEE',
        actionButtonHoverBg: '#6F4FCC',
        actionButtonHoverColor: '#1D1927',
        actionButtonForDarkBg: '#342D48',
        actionButtonForDarkColor: '#EEEEEE',
        actionButtonForDarkHoverBg: '#6F4FCC',
        actionButtonForDarkHoverColor: '#FFFFFF',
        cancelButtonColor: '#6C6C6C',
        cancelButtonHoverColor: '#1A1C1D',
        primaryButtonBg: '#342D48',
        buttonColor: '#DADADA',
        buttonBorder: '#383839',
        buttonHover: '#222526',
        buttonLoader: '#EEEEEE',

        cardBorder: '#383839',
        dashboardCardBorder: '#383839',
        infoCardBorder: '#383839',

        nameField: '#A0A0A0',
        nameFieldBack: '#6f6f6f',
        descriptionTitle: '#DADADA',
        descriptionText: '#A0A0A0',
        inputFocus: '#222526',
        loginInputColor: '#DADADA',
        inputColor: '#DADADA',
        inputExpandedPanelColor: '#DADADA',
        inputColorHover: '#EEEEEE',
        inputColorChevron: '#A0A0A0',
        inputColorChevronHover: '#DADADA',
        selectArrow: '#DADADA',
        selectColor: '#383839',
        selectOnPageChevron: '#A0A0A0',
        selectChevron: '#A0A0A0',
        inputNumberDefaultUnderscore: '#383839',

        switchBg: '#383839',
        switchBorder: '#6C6C6C',
        switchActiveBg: '#342D48',
        switchActiveBorder: '#6F4FCC',
        switchActiveGreenBg: '#27320E',
        switchActiveGreenBorder: '#9CE500',

        toggleButtonBg: '#342D48',
        toggleButtonActiveBg: '#6F4FCC',
        toggleButtonColor: '#EEEEEE',
        toggleButtonActiveColor: '#1D1927',
        toggleButtonHoverColor: '#1D1927',

        switchButton: '#6F4FCC',
        switchButtonActive: '#6F4FCC',
        switchButtonColor: '#F4F0F9',
        switchButtonHoverColor: '#F4F0F9',
  
        outlinedButton: '#a0a0a0',
        creditCardBg: '#232526',

        copyBtnBg: '#342D48',
        copyBtnBgHover: '#6F4FCC',
        copyBtnColor: '#DADADA',
        copyBtnColorHover: '#DADADA',

        expandPanelBg: '#101010',
        cardChildBg: '#101010',

        tableBg: '#1A1C1D',
        tableBgEven: '#1E2021',
        tableHeaderText: '#EEEEEE',
        tableBodyText: '#A0A0A0',

        paginationBg: '#222526',
        paginationColor: '#6C6C6C',
        paginationBorder: '#383839',
        paginationActiveColor: '#A0A0A0',
        paginationArrowColor: '#383839',

        completeStatus: '#9CE500',
        incompleteStatus: '#F06269',

        toasterInfo: '#342D48',
        toasterSuccess: '#9CE500',
        toasterError: '#F06269',

        tooltipBg: '#342D48',
        tooltipColor: '#C79BFF',
        tooltipIcon: '#9E9E9E',

        pieChartBorder: '#6F4FCC',
        cardChartBg: '#1A1C1D',
        chartTitle: '#EEEEEE',
        chartAxisTitle: '#EEEEEE',
        chartAxisLabels: '#A0A0A0',
        chartAxisXLabels: '#EEEEEE',
        chartLineColor: '#383839',
        chartHeaderColor: '#A0A0A0',
        chartLegendColor: '#A0A0A0',
        chartLegendHoverColor: '#EEEEEE',
        chartTextColor: '#EEEEEE',
        chartStackLabelColor: '#EEEEEE',
        chartBarLabelColorLight: '#EEEEEE',
        chartBarLabelColorDark: '#342D48',

        cardFilterBg: '#1A1C1D',
        overlayColor: '#111111',

        dateRangePickerBg: '#1a1c1d',
        dateRangePickerColor: '#A0A0A0',
        dateRangePickerActiveColor: '#EEEEEE',
        headerInputBg: '#1E2021',
        inputBorder: '#383839',
        inputBorderDisabled: '#222526',
        inputIconDisabled: '#585858',
        inputBorderHover: '#585858',

        timePickerBg: '#222526',
        timePickerTitle: '#A0A0A0',
        timePickerIcon: '#493A79',
        timePickerIconHover: '#6F4FCC',
        timePickerBorder: '#585858',
        timePickerColor: '#585858',
        timePickerColorActive: '#EEEEEE',

        dialogTitle: '#EEEEEE',
        dialogConfirmTitle: '#A0A0A0',
        dialogConfirmSubTitle: '#EEEEEE',
        dialogConfirmQuestion: '#6C6C6C',
        dialogShowCodeTitle: '#DADADA',
        dialogShowCodeSubTitle : '#A0A0A0',
        dialogShowCodeColor : '#6C6C6C',
        dialogShowCodeBg : '#101010',
        dialogShowCodeAlarmBorder : '#342D48',

        tabColor: '#585858',
        tabHoverColor: '#EEEEEE',

        autoRefreshBtnBg: '#1E2021',
        autoRefreshBtnColor: '#DADADA',
        autoRefreshBtnColorHover: '#EEEEEE',
        autoRefreshBtnActiveColor: '#6F4FCC',
        autoRefreshBorder: '#383839',
        autoRefreshBorderHover: '#585858',
        autoRefreshBorderActive: '#796496',
        autoRefreshBorderActiveHover: '#6F4FCC',

        filterBtnBg: '#1E2021',
        filterBtnColor: '#DADADA',
        filterBtnLabelColor: '#A0A0A0',
        filterBtnColorHover: '#EEEEEE',
        filterBtnIconColor: '#6F4FCC',
        filterBtnIconColorHover: '#F06269',
        filterBtnBorder: '#383839',
        filterBtnBorderHover: '#585858',

        resetBtnBg: '#6F4FCC',
        resetBtnColor: '#1D1927',
        resetBtnColorHover: '#F4F0F9',

        cardTimePickerDay: '#DADADA',
        cardTimePickerCloseOpen: '#A0A0A0',
        cardTimePickerTime: '#6C6C6C',
        cardTimePickerBorder: '#6F4FCC',
        cardTimePickerDayOff: '#6C6C6C',
        cardTimePickerTimeOff: '#383839',
        cardTimePickerBorderOff: '#383839',

        pbxBg: '#101010',
        pbxInfoBorder: '#383839',
        pbxAlarmIcon: '#383839',
        pbxTextInfo: '#585858',
        pbxTextLink: '#6F4FCC',
        pbxTextTitle: '#DADADA',
        pbxTextSubTitle: '#6C6C6C',
        pbxFieldName: '#A0A0A0',
        pbxCancelBtnBorder: '#383839',
        pbxActionBtnBg: '#342D48',
        pbxActionBtnText: '#EEEEEE',
        pbxActionBtnTextHover: '#FFFFFF',

        pendingColor: '#FFDA1A',
        acceptedColor: '#6F4FCC',

        inviteCardBg: '#101010',




        activeButtonBg: '#6F4FCC',
        primaryButtonColor: '#EEEEEE',
        buttonActiveColor: '#FFFFFF',
        testNumberColor: '#6C6C6C',
        hourlyPickerColor: '#6F4FCC',

        arrowIcon: '#F4F0F9',

        inputNumberDefaultColor: '#A0A0A0',

        sectionTitle: '#383839',

        pbxLayoutBg: '#101010',
        selectDefault: '#1A1C1D',
        selectFocus: '#222526',
        selectItem: '#6F4FCC',

        checkboxPrimaryColor: '#6F4FCC',
        checkboxTextColor: '#585858',

        textLink: '#6C6C6C',
        textLinkActive: '#DADADA',
        textLinkHover: '#DADADA',
        textLinkUnHover: '#A0A0A0',

        error: '#F06269',
        createBorder: '#F06269',
        toggleButtonActive: '#1D1927',

        iconViolet: '#6F4FCC',
        iconLightGray: '#D1D1D7',
        iconWhite: '#FFFFFF',

        black: '#1A1C1D',
        blackLight: '#1E2021',
        doubleBlack: '#101010',
        violetBlackLight: '#342D48',
        grayBlack: '#222526',
        gray: '#6C6C6C',
        gray2: '#585858',
        grayLighten2: '#DADADA',
        grayLighten1: '#EEEEEE',
        violetBlack: '#1D1927',
        grayBlack2: '#383839',

        childNameColor: '#DADADA',
        childEmailColor: '#6C6C6C',

        datePickerBg: '#101010',
        rangePickerBg: '#101010',

        eventTitle: '#A0A0A0',
        eventValue: '#6C6C6C',

        playerTitle: '#A0A0A0',
        playerNumber: '#EEEEEE',
        playerBg: '#1A1C1D',

        defaultIconColor: '#6C6C6C',

        appBarNotification: '#342D48',
        appBarNotificationText: '#DADADA',
        appBarNotificationTitle: '#EEE',
        appBarNotificationBtn: '#9775FA',

        titleMigration: '#FFFFFF',
        descriptionMigration: '#A0A0A0',
        bgStepMigration: '#222526',
        bgStepContentMigration: '#1A1C1D',

        borderProcessMigration: '#383839',
        circleProcessMigration: '#383839',
        headerProcessMigration: '#1E2021',
        textProcessMigration: '#ffffff',
        infoProcessMigration: '#222526',
        infoTextProcessMigration: '#A0A0A0',

        borderGray: '#4C4C4C'
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sp: 365,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1440
    }
  }
})

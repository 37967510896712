export default {
  methods: {
    currency(value = 0, currency = 'USD', minimumFractionDigits = 2) {
      return new Intl.NumberFormat('en-US',{
        style: 'currency',
        minimumFractionDigits,
        currency
      }).format(isNaN(value) ? 0 : +value)
    }
  }
}

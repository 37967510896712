<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="606px"
    :fullscreen="!checkSm"
    persistent
    overlay-color="overlayColor"
  >
    <div class="cardBg dialog-migration d-flex flex-column justify-space-between">
      <div class="mb-20">
        <div
          class="dialog-migration__cancel-icon"
          @click="closeDialog"
        >
          <v-icon size="24">$closeIcon</v-icon>
        </div>

        <div class="mb-10 d-flex align-center">
          <div class="mr-8 font-large titleMigration--text">upgrade to Dialics 2.0</div>

          <img src="/images/party_popper.png">
        </div>

        <div class="mb-20 descriptionMigration--text font-normal">
          The current version will stay available for another {{ value }} days. Let’s explore the new features of Dialics 2.0 and upgrade your version when you're ready.
        </div>

        <div class="dialog-migration__video">
          <iframe
            v-if="show"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/L_XF2GV67Yg?si=wFPjiMUH4QJ9w6g4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
      </div>

      <div class="d-flex justify-end flex-sm-row flex-column">
        <CancelButton
          class="mr-sm-10 mb-sm-0 mb-10"
          @click="closeDialog"
        >
          Cancel
        </CancelButton>

        <ActionButton @click="onLearnMore">Learn more</ActionButton>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CancelButton from '@/components/buttons/CancelButton.vue'
import ActionButton from '@/components/buttons/ActionButton.vue'

import { setMigrationNotification, getMigrationNotification } from '@/helper/migration-local-storage-helper'
import { dateFormatDistanceForSupport } from '@/helper/app-helper'

export default {
  name: 'DialogMigration',
  components: { ActionButton, CancelButton },

  data: () => ({
    show: false
  }),
  computed:{
    checkSm() {
      return this.$vuetify.breakpoint.width >= 600
    },
    value() {
      const result = dateFormatDistanceForSupport()
      const [number] = result.split(' ')

      return Number(number)
    }
  },
  methods: {
    closeDialog() {
      this.show = false
    },
    onLearnMore() {
      this.closeDialog()
      this.$router.push({ name: 'MigrationGuide' })
    }
  },
  beforeMount() {
    if (!getMigrationNotification()) {
      setMigrationNotification(true)

      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>

.dialog-migration {
  padding: 20px;
  height: 100%;

  @media screen and (min-width: 600px) {
    padding: 70px;
    height: auto;
  }

  &__cancel-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__video {
    border-radius: 10px;
    height: 203px;
    overflow: hidden;


    @media screen and (min-width: 600px) {
      height: 256px;
    }
  }
}
</style>

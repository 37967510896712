<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.55018 15.711L18.0252 7.23604C18.2252 7.03604 18.4628 6.93604 18.7382 6.93604C19.0135 6.93604 19.2508 7.03604 19.4502 7.23604C19.6502 7.43604 19.7502 7.6737 19.7502 7.94904C19.7502 8.22437 19.6502 8.4617 19.4502 8.66104L10.2502 17.861C10.0502 18.061 9.81685 18.161 9.55018 18.161C9.28351 18.161 9.05018 18.061 8.85018 17.861L4.55018 13.561C4.35018 13.361 4.25418 13.1234 4.26218 12.848C4.27018 12.5727 4.37451 12.3354 4.57518 12.136C4.77518 11.936 5.01285 11.836 5.28818 11.836C5.56351 11.836 5.80085 11.936 6.00018 12.136L9.55018 15.711Z" fill="#88D100" stroke="#88D100"/>
  </svg>
</template>


<script>
export default {
  name: 'OkIcon'
}
</script>
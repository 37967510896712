<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6752 15.3698L2.30542 0H17C25.839 0 33.1024 6.74574 33.9228 15.3698H17.6752Z" fill="#F06269"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6753 18.6299H33.9228C33.1024 27.254 25.839 33.9997 17 33.9997H2.30542L17.6753 18.6299Z" fill="#FFDA1A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.30566V31.6949L14.6946 17.0003L0 2.30566Z" :fill="isDark ? '#6F4FCC' : '#ffffff'"/>
  </svg>
</template>

<script>
export default {
  name: 'OnlyLogoIcon',
  inheritAttrs: false,
  computed: {
    isDark() {
      return this.$vuetify.theme.isDark
    }
  },
}
</script>
<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.4974 9.16667C9.33835 9.16667 10.8307 7.67428 10.8307 5.83333C10.8307 3.99238 9.33835 2.5 7.4974 2.5C5.65645 2.5 4.16406 3.99238 4.16406 5.83333C4.16406 7.67428 5.65645 9.16667 7.4974 9.16667Z" stroke="#A0A0A0" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5" stroke="#A0A0A0" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3359 2.60938C14.0529 2.79296 14.6885 3.20996 15.1423 3.79463C15.5961 4.37931 15.8425 5.0984 15.8425 5.83854C15.8425 6.57868 15.5961 7.29777 15.1423 7.88245C14.6885 8.46712 14.0529 8.88412 13.3359 9.06771" stroke="#A0A0A0" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625" stroke="#A0A0A0" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'UsersIcon',
  inheritAttrs: false,
}
</script>
<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="rounded"
    :height="height"
    :width="width"
    :min-width="minWidth"
    :ripple="false"
    depressed
    icon
    outlined
  >
    <v-icon :color="color" :size="size">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'IconOutlinedBtn',
  inheritAttrs: false,
  props: {
    height: {
      type: Number | String,
      default: 48
    },
    minWidth: {
      type: Number | String,
      default: 54
    },
    width: {
      type: Number | String,
      default: 54
    },
    size: {
      type: Number | String,
      default: 54
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'iconGray'
    }
  }
}
</script>

<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M9 15L4 10L9 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M4 10H16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'BackIcon',
  inheritAttrs: false
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M3 3V17H17V3H3Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>
<script>
export default {
  name: 'StopIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].iconOnTable
    }
  }
}
</script>

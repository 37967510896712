<template>
  <svg v-bind="$attrs" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="stroke-to-change" d="M2 7.65686L7.65685 13.3137L13.3137 7.65686" stroke="#6C6C6C" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ArrowDown',
  inheritAttrs: false,
}
</script>

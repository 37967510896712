<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M4 6L16 6M6 10L14 10M9 14L11 14" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'FilterIcon',
  inheritAttrs: false,
}
</script>

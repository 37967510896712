<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M10 16.6663V8.33301" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M15 16.6663V3.33301" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M5 16.6663V13.333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'ReportingIcon',
  inheritAttrs: false,
}
</script>

import auth from '../middleware/auth'
import manager from '../middleware/manager'
import syncNumbers from '../middleware/syncNumbers'
import allUsers from '../middleware/allUsers'
import withBuyer from '../middleware/withBuyer'
import withVendor from '../middleware/withVendor'
import checkPbx from '../middleware/checkPbx'
import checkSubscription from '../middleware/checkSubscription'
import vendorPermissionCreateNumbers from '../middleware/vendorPermissionCreateNumbers'
import checkAccountDisabled from '../middleware/checkAccountDisabled'
import isMigrationGuide from '../middleware/isMigrationGuide'

export default [{
  path: '/dashboard', name: 'Dashboard', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard')
}, {
  path: '/reporting', name: 'Reporting', meta: { layout: 'main', middleware: [auth, allUsers, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "reporting" */ '@/views/Reporting')
}, {
  path: '/create-campaign', name: 'CreateCampaign', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_campaign" */ '@/views/campaigns/DetailCampaign')
}, {
  path: '/campaigns/:slug', name: 'DetailCampaign', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_campaign" */ '@/views/campaigns/DetailCampaign')
}, {
  path: '/campaigns', name: 'ManageCampaigns', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_campaigns" */ '@/views/campaigns/ManageCampaigns')
}, {
  path: '/create-routing-plan', name: 'CreateRoutingPlan', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_routing_plans" */ '@/views/ivr/DetailRoutingPlan')
}, {
  path: '/routing-plan/:slug', name: 'DetailRoutingPlan', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_routing_plans" */ '@/views/ivr/DetailRoutingPlan')
}, {
  path: '/routing-plans', name: 'ManageRoutingPlans', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_routing_plans" */ '@/views/ivr/ManageRoutingPlans')
}, {
  path: '/create-ivr', name: 'CreateIvr', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_ivr" */ '@/views/ivr/DetailIvr')
}, {
  path: '/ivr/:slug', name: 'DetailIvr', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_ivr" */ '@/views/ivr/DetailIvr')
}, {
  path: '/ivr', name: 'ManageIvr', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_ivr" */ '@/views/ivr/ManageIvr')
}, {
  path: '/medias', name: 'ManageMedias', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_medias" */ '@/views/ivr/ManageMedias')
}, {
  path: '/vendors', name: 'ManageVendors', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_vendors" */ '@/views/vendors/ManageVendors')
}, {
  path: '/create-vendor', name: 'CreateVendor', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_vendor" */ '@/views/vendors/DetailVendor')
}, {
  path: '/vendors/:slug', name: 'DetailVendor', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "detail_vendor" */ '@/views/vendors/DetailVendor')
}, {
  path: '/destinations', name: 'ManageDestinations', meta: { layout: 'main', middleware: [auth, withBuyer, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_destinations" */ '@/views/destinations/ManageDestinations')
}, {
  path: '/create-destination', name: 'CreateDestination', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_destination" */ '@/views/destinations/DetailDestination')
}, {
  path: '/destination/:slug', name: 'DetailDestination', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_destination" */ '@/views/destinations/DetailDestination')
}, {
  path: '/groups', name: 'ManageGroups', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_groups" */ '@/views/destinations/ManageGroups')
}, {
  path: '/create-group', name: 'CreateGroup', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_group" */ '@/views/destinations/DetailGroup')
}, {
  path: '/groups/:slug', name: 'DetailGroup', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_group" */ '@/views/destinations/DetailGroup')
}, {
  path: '/buyers', name: 'ManageBuyer', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_buyers" */ '@/views/buyers/ManageBuyers')
}, {
  path: '/create-buyer', name: 'CreateBuyer', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_buyer" */ '@/views/buyers/DetailBuyer')
}, {
  path: '/buyers/:slug', name: 'DetailBuyer', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_buyer" */ '@/views/buyers/DetailBuyer')
}, {
  path: '/numbers', name: 'ManageNumbers', meta: { layout: 'main', middleware: [auth, vendorPermissionCreateNumbers, withVendor, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_numbers" */ '@/views/numbers/ManageNumbers')
}, {
  path: '/create-number', name: 'CreateNumber', meta: { layout: 'main', middleware: [auth, vendorPermissionCreateNumbers, withVendor, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_number" */ '@/views/numbers/CreateNumber')
}, {
  path: '/sync-numbers', name: 'SyncNumbers', meta: { layout: 'main', middleware: [auth, manager, syncNumbers, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "sync_numbers" */ '@/views/numbers/SyncNumbers')
}, {
  path: '/pools', name: 'ManagePools', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_pools" */ '@/views/numbers/ManagePools')
}, {
  path: '/create-pool', name: 'CreatePool', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_pool" */ '@/views/numbers/DetailPool')
}, {
  path: '/pools/:id', name: 'DetailPool', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_pool" */ '@/views/numbers/DetailPool')
}, {
  path: '/blocked-numbers', name: 'BlockedNumbers', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "blocked_numbers" */ '@/views/numbers/BlockedNumbers')
// }, {
//   path: '/numbers-fees', name: 'NumbersFees', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "numbers_fees" */ '@/views/numbers/NumbersFees')
// }, {
//   path: '/url-params', name: 'ManageUrlParams', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "manage_url_params" */ '@/views/integrations/ManageUrlParams')
// }, {
//   path: '/create-url-params', name: 'CreateUrlParams', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "create_url_params" */ '@/views/integrations/DetailUrlParams')
// }, {
//   path: '/url-params/:id', name: 'DetailUrlParams', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "detail_url_params" */ '@/views/integrations/DetailUrlParams')
// }, {
//   path: '/pixels', name: 'ManagePixels', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "manage_pixels" */ '@/views/integrations/ManagePixels')
// }, {
//   path: '/create-pixels', name: 'CreatePixel', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "create_pixel" */ '@/views/integrations/DetailPixel')
// }, {
//   path: '/pixels/:id', name: 'DetailPixel', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
//   component: () => import(/* webpackChunkName: "detail_pixel" */ '@/views/integrations/DetailPixel')
}, {
  path: '/traffic-sources', name: 'ManageTrafficSources', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_traffic_source" */ '@/views/integrations/ManageTrafficSources')
}, {
  path: '/add-traffic-source', name: 'AddTrafficSource', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "create_traffic_source" */ '@/views/integrations/DetailTrafficSource')
}, {
  path: '/traffic-source/:slug', name: 'DetailTrafficSource', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "detail_traffic_source" */ '@/views/integrations/DetailTrafficSource')
}, {
  path: '/account-details', name: 'AccountDetails', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "account_detail" */ '@/views/settings/AccountDetails')
}, {
  path: '/users', name: 'ManageUsers', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "manage_users" */ '@/views/settings/ManageUsers')
}, {
  path: '/invite-user', name: 'InviteUser', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "invite_user" */ '@/views/settings/InviteUser')
}, {
  path: '/payment-history', name: 'PaymentHistory', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "payment_history" */ '@/views/settings/PaymentHistory')
}, {
  path: '/fill-balance', name: 'FillBalance', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "fill_balance" */ '@/views/settings/FillBalance')
}, {
  path: '/manage-cards', name: 'ManageCards', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "manage_cards" */ '@/views/settings/ManageCards')
}, {
  path: '/login-security', name: 'LoginSecurity', meta: { layout: 'main', middleware: [auth, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "login_security" */ '@/views/settings/LoginSecurity')
}, {
  path: '/two-factor-auth', name: 'TwoFactorAuth', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "two_factor_auth" */ '@/views/settings/TwoFactorAuth')
}, {
  path: '/protection', name: 'ProtectionCallLog', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "protection_call_log" */ '@/views/settings/ProtectionCallLog')
}, {
  path: '/api-access-tokens', name: 'ApiAccessTokens', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription] },
  component: () => import(/* webpackChunkName: "api_access_tokens" */ '@/views/settings/ManageApiTokens')
}, {
  path: '/migration-guide', name: 'MigrationGuide', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled, isMigrationGuide] },
  component: () => import(/* webpackChunkName: "MigrationGuide" */ '@/views/settings/MigrationGuide/MigrationGuide')
}, {
  path: '/migration-process', name: 'MigrationProcess', meta: { layout: 'main', middleware: [auth, manager, checkPbx, checkSubscription, checkAccountDisabled] },
  component: () => import(/* webpackChunkName: "MigrationProcess" */ '@/views/migrationProcess/MigrationProcess')
}]
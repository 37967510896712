<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1.69931C8 1.11205 8.47606 0.635986 9.06332 0.635986H22.2701C23.0627 0.635986 23.5767 1.47201 23.2189 2.17929L18.3506 11.8026C17.9928 12.5099 18.5068 13.346 19.2994 13.346H22.1442C22.9657 13.346 23.4769 14.238 23.0616 14.9468L14.7657 29.1052C14.2175 30.0407 12.7849 29.6519 12.7849 28.5676V19.1942C12.7849 18.6069 12.3089 18.1309 11.7216 18.1309H9.06332C8.47606 18.1309 8 17.6548 8 17.0676V1.69931Z" fill="currentColor"/>
  </svg>
</template>

<script>

export default {
  name: 'FlashIcon'
}
</script>
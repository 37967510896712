<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EEEEEE" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8 9.00113C14.6188 8.68689 14.3557 8.42784 14.0386 8.25169C13.7215 8.07554 13.3625 7.98893 13 8.00113H11C10.4696 8.00113 9.96086 8.21185 9.58579 8.58692C9.21071 8.96199 9 9.4707 9 10.0011C9 10.5316 9.21071 11.0403 9.58579 11.4153C9.96086 11.7904 10.4696 12.0011 11 12.0011H13C13.5304 12.0011 14.0391 12.2118 14.4142 12.5869C14.7893 12.962 15 13.4707 15 14.0011C15 14.5316 14.7893 15.0403 14.4142 15.4153C14.0391 15.7904 13.5304 16.0011 13 16.0011H11C10.6375 16.0133 10.2785 15.9267 9.96142 15.7506C9.64435 15.5744 9.38115 15.3154 9.2 15.0011"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 16V18M12 6V8V6Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Balance-icon',
  inheritAttrs: false,
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Types as authTypes } from '@/store/modules/auth'
import { getAccessToken } from '@/helper/auth-helper'

import nextFactory from './middleware/nextFactory'
import isNotAuth from './middleware/isNotAuth'
import auth from './middleware/auth'
import pbx from './middleware/pbx'
import selectAccounts from './middleware/selectAccounts'
import subscription from './middleware/subscription'
import notFound from './middleware/notFound'
import isAccountDisabled from './middleware/isAccountDisabled'

import app from './modules/app'

Vue.use(VueRouter)

const baseRoutes = [{
//   path: '/login', name: 'Login', meta: { layout: 'empty', middleware: isNotAuth },
//   component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login')
// }, {
  path: '/auth', name: 'Auth',
  redirect: { name: 'AuthLogin' },
  component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Auth'),
  children: [{
    name: 'AuthLogin',
    path: 'login',
    meta: { layout: 'empty', gaEnable: true, middleware: isNotAuth, title: 'Dialics Login' },
    component: () => import(/* webpackChunkName: "auth_login" */ '@/views/auth/AuthLogin'),
  }, {
    name: 'AuthReset',
    path: 'reset',
    meta: { layout: 'empty', middleware: isNotAuth },
    component: () => import(/* webpackChunkName: "auth_reset" */ '@/views/auth/AuthReset'),
  }, {
    name: 'AuthRegister',
    path: 'register',
    meta: { layout: 'empty', gaEnable: true, middleware: isNotAuth },
    component: () => import(/* webpackChunkName: "auth_register" */ '@/views/auth/AuthRegister'),
  }]
}, {
  path: '/login-as', name: 'LoginAs', meta: { layout: 'empty', middleware: selectAccounts },
  component: () => import(/* webpackChunkName: "login_as" */ '@/views/auth/ManagerAccounts')
}, {
  path: '/registration', name: 'Registrations', meta: { layout: 'empty', middleware: isNotAuth, title: 'Dialics - Registration' },
  component: () => import(/* webpackChunkName: "registration" */ '@/views/auth/Registrations')
}, {
  path: '/invite/:token', name: 'InviteUsers', meta: { layout: 'empty' }, props: true,
  component: () => import(/* webpackChunkName: "invite-user" */ '@/views/auth/InviteUsers')
}, {
  path: '/reset-password/:token', name: 'ResetPassword', meta: { layout: 'empty', middleware: isNotAuth }, props: true,
  component: () => import(/* webpackChunkName: "reset-password" */ '@/views/auth/ResetPassword')
}, {
  path: '/register/confirm/:token', name: 'RegisterConfirmation', meta: { layout: 'empty', middleware: isNotAuth }, props: true,
  component: () => import(/* webpackChunkName: "register-confirmation" */ '@/views/auth/RegisterConfirmation')
}, {
  path: '/connect-pbx', name: 'ConnectPbx', meta: { layout: 'empty', middleware: [auth, pbx] },
  component: () => import(/* webpackChunkName: "connect-pbx" */ '@/views/auth/ConnectPbx')
}, {
  path: '/subscription', name: 'Subscription', meta: { layout: 'empty', middleware: [auth, subscription] },
  component: () => import(/* webpackChunkName: "subscription" */ '@/views/auth/Subscription')
}, {
  path: '/account-disabled', name: 'AccountDisabled', meta: { layout: 'empty', middleware: [auth, isAccountDisabled] },
  component: () => import(/* webpackChunkName: "account_disabled" */ '@/views/auth/AccountDisabled')
}, {
  path: '/not-found', alias: '*', name: 'NotFound', meta: { layout: 'empty', middleware: notFound },
  component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
}]

const routes = baseRoutes.concat(
  app
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!!getAccessToken() && !store.state.auth.role) {
    store.commit(authTypes.mutations.RESET_STATE)
    await store.dispatch(authTypes.actions.GET_CHECK)
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { to, from, next, store, router }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router

<template>
  <div id="app">
    <v-app>
      <component :is="layout">
        <router-view/>
      </component>
  
      <notifications
        class="mr-5"
        :class="visibleAppVersionNotification ? 'stack-group' : 'mt-10'"
        width="350"
      />

      <notifications
        group="app_version"
        class="mt-10 mr-5"
        width="350"
      >
        <template slot="body" slot-scope="props">
          <div class="vue-notification-template vue-notification primary pointer" @click="reloadApp">
            <div class="notification-content" v-html="props.item.text"></div>
          </div>
        </template>
      </notifications>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { Types as indexTypes } from '@/store'
import { Types as authTypes } from '@/store/modules/auth'
import { getOnPage, setOnPage, reloadAllWindow, setReloadWatcher, setLocalStorageWatch, removeReloadItems } from '@/helper/app-helper'

import MainLayout from '@/layouts/MainLayout'
import AdminLayout from '@/layouts/AdminLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

export default {
  name: 'App',
  data: () => ({
    toast: false,
    overlay: true,
    visibleAppVersionNotification: false,
  }),
  components: { MainLayout, AdminLayout, EmptyLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    ...mapState({
      message: state => state.message,
      type: state => state.type,
      accountId: state => state.auth.accountId,
      redirectLogin: state => state.auth.redirectLogin,
      redirectForbidden: state => state.auth.redirectForbidden,
      pbx: state => state.auth.pbx,
      appVersionChanged: state => state.auth.appVersionChanged,
      selectAccounts: state => state.auth.selectAccounts,
    }),
  },
  created() {
    setReloadWatcher()
    removeReloadItems()
    this.getTimezones()
    this.getCountries()

    if (!getOnPage()) {
      setOnPage(25)
    }

    setLocalStorageWatch()
  },
  mounted() {
    if (!!window.ga) {
      ga('create', 'GTM-WK578ZP')
      ga('send', 'pageview')
    }
  },
  methods: {
    ...mapMutations({
      clearMessage: indexTypes.mutations.CLEAR_MESSAGE,
      changeRedirectForbidden: authTypes.mutations.REDIRECT_FORBIDDEN,
    }),
    ...mapActions({
      getCountries: indexTypes.actions.GET_COUNTRIES,
      getTimezones: indexTypes.actions.GET_TIMEZONES,
    }),
    reloadApp() {
      reloadAllWindow()
    }
  },
  watch: {
    message: {
      handler(text) {
        if (text) {
          this.$notify({ type: this.type, text, duration: this.type === 'error-bg' ? 15000 : 6000 })
          this.clearMessage()
        }
      },
    },
    redirectForbidden: {
      immediate: true,
      handler(redirect) {
        if (!!redirect) {
          this.changeRedirectForbidden(false)
          this.$router.push({ name: 'Reporting' })
        }
      },
    },
    pbx: {
      immediate: true,
      handler(pbx) {
        if (!pbx) {
          this.$router.push({ name: 'ConnectPbx' })
        }
      },
    },
    accountId: {
      handler(accountId, oldAccountId) {
        if (!!this.selectAccounts) {
          return
        }

        if (!!oldAccountId && !!accountId && accountId !== oldAccountId && this.$route.name !== 'Reporting' && this.$route.name !== 'Registrations') {
          return this.$router.push({ name: 'Reporting' })
        }

        if (!accountId) {
          return this.$router.push({ name: 'AuthLogin' })
        }
      },
    },
    appVersionChanged: {
      handler(value) {
        if (value) {
          this.visibleAppVersionNotification = true
          this.$notify({
            group: 'app_version',
            type: 'success',
            text: 'Application has been updated. Please click to apply changes',
            duration: -1
          })
        }
      }
    },
    selectAccounts: {
      handler(value) {
        if (value) {
          if (this.$route.name !== 'LoginAs') {
            this.$router.push({ name: 'LoginAs' })
          }
        }
      }
    },
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Dialics'
      }
    },
  },
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M15.3077 6H7.69231C6.75767 6 6 6.75767 6 7.69231V15.3077C6 16.2423 6.75767 17 7.69231 17H15.3077C16.2423 17 17 16.2423 17 15.3077V7.69231C17 6.75767 16.2423 6 15.3077 6Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M3.30769 11H2.53846C2.13044 11 1.73912 10.8379 1.4506 10.5494C1.16209 10.2609 1 9.8696 1 9.4615V2.53846C1 2.13044 1.16209 1.73912 1.4506 1.4506C1.73912 1.16209 2.13044 1 2.53846 1H9.4615C9.8696 1 10.2609 1.16209 10.5494 1.4506C10.8379 1.73912 11 2.13044 11 2.53846V3.30769" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'CopyIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0026 11.6654C10.9231 11.6654 11.6693 10.9192 11.6693 9.9987C11.6693 9.07822 10.9231 8.33203 10.0026 8.33203C9.08213 8.33203 8.33594 9.07822 8.33594 9.9987C8.33594 10.9192 9.08213 11.6654 10.0026 11.6654Z" stroke="#EEEEEE" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3307 10.0013C16.1082 13.8905 13.3307 15.8346 9.9974 15.8346C6.66406 15.8346 3.88656 13.8905 1.66406 10.0013C3.88656 6.11214 6.66406 4.16797 9.9974 4.16797C13.3307 4.16797 16.1082 6.11214 18.3307 10.0013Z" stroke="#EEEEEE" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Eye-light-icon',
  inheritAttrs: false,
}
</script>
<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M4.39109 3.43235C2.53796 5.01637 1.36328 7.37099 1.36328 9.99998C1.36328 14.7697 5.22991 18.6363 9.99965 18.6363C14.7694 18.6363 18.636 14.7697 18.636 9.99998C18.636 7.37099 17.4613 5.01637 15.6082 3.43235M9.99965 1.81836V9.09109" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'TurnOffIcon',
  inheritAttrs: false,
}
</script>

<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M16.364 6.5V16.5456C16.364 17.4494 15.55 18.182 14.5458 18.182H5.4549C4.45075 18.182 3.63672 17.4494 3.63672 16.5456V3.45472C3.63672 2.55098 4.45075 1.81836 5.4549 1.81836H11.5M16.364 6.5L11.5 1.81836M16.364 6.5H11.5V1.81836" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M7.006 15L7 15L10 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M7.012 12L7 12L13 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'Description-icon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
          this.$vuetify.theme.themes[this.isTheme].actionIconHover :
          this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>
<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :stroke="getColor(hover)" d="M18.334 3.33301V8.33301H13.334" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="getColor(hover)" d="M16.2416 12.5003C15.6999 14.0336 14.6745 15.3493 13.32 16.2491C11.9655 17.1489 10.3553 17.5841 8.73192 17.4892C7.10855 17.3942 5.56003 16.7742 4.3197 15.7226C3.07936 14.6709 2.21442 13.2447 1.8552 11.6587C1.49599 10.0727 1.66196 8.41299 2.32812 6.92956C2.99427 5.44613 4.12451 4.21939 5.54853 3.4342C6.97254 2.64902 8.61318 2.34792 10.2232 2.57629C11.8332 2.80466 13.3254 3.55012 14.4749 4.70034L18.3332 8.33367" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'ResendIcon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].actionIcon
    }
  }
}
</script>
<template>
  <v-hover #default="{ hover }">
    <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.49904 14.4996C3.9989 14.4998 1.49897 13.4997 1.49932 11C1.49975 8.00013 5.49966 7.49999 5.49966 7.49999C5.49966 7.49999 6.49916 4.04125 9.99932 3.99999C14.2713 3.94962 14.9992 8.00013 14.9992 8.00013C14.9992 8.00013 18.4973 8.00002 18.4984 11.5004C18.4992 14 15.4983 14.4996 14.4992 14.4996" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.0003 11.0003L10.0001 8.99992" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.9997 9.0003L12.0001 11.0001" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 10V17" :stroke="getColor(hover)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </v-hover>
</template>

<script>
export default {
  name: 'Upload-icon',
  inheritAttrs: false,
  computed: {
    isTheme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    }
  },
  methods: {
    getColor(hover) {
      return hover ?
        this.$vuetify.theme.themes[this.isTheme].actionIconHover :
        this.$vuetify.theme.themes[this.isTheme].iconOnTable
    }
  }
}
</script>

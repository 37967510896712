<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7322 4.75837L29.2442 24.697C29.4198 25.0011 29.5122 25.346 29.5122 25.697C29.5122 26.0481 29.4198 26.393 29.2442 26.697C29.0687 27.0011 28.8162 27.2535 28.5122 27.4291C28.2082 27.6046 27.8633 27.697 27.5122 27.697H4.48823C4.13716 27.697 3.79228 27.6046 3.48825 27.4291C3.18422 27.2535 2.93175 27.0011 2.75622 26.697C2.58069 26.393 2.48828 26.0481 2.48828 25.697C2.48828 25.346 2.5807 25.0011 2.75623 24.697L14.2682 4.75837C15.0376 3.42504 16.9616 3.42504 17.7322 4.75837ZM16.0002 20.561C15.6466 20.561 15.3075 20.7015 15.0574 20.9516C14.8074 21.2016 14.6669 21.5407 14.6669 21.8944C14.6669 22.248 14.8074 22.5871 15.0574 22.8372C15.3075 23.0872 15.6466 23.2277 16.0002 23.2277C16.3539 23.2277 16.693 23.0872 16.943 22.8372C17.1931 22.5871 17.3336 22.248 17.3336 21.8944C17.3336 21.5407 17.1931 21.2016 16.943 20.9516C16.693 20.7015 16.3539 20.561 16.0002 20.561ZM16.0002 11.2277C15.6737 11.2277 15.3584 11.3476 15.1144 11.5647C14.8704 11.7817 14.7144 12.0807 14.6762 12.405L14.6669 12.561V17.8944C14.6673 18.2342 14.7974 18.5611 15.0307 18.8082C15.264 19.0553 15.5828 19.204 15.9221 19.2239C16.2613 19.2438 16.5954 19.1335 16.856 18.9154C17.1166 18.6972 17.2841 18.3878 17.3242 18.0504L17.3336 17.8944V12.561C17.3336 12.2074 17.1931 11.8683 16.943 11.6182C16.693 11.3682 16.3539 11.2277 16.0002 11.2277Z" fill="currentColor"/>
  </svg>
</template>


<script>
export default {
  name: "AttentionIcon"
}
</script>
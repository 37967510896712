<template>
  <div class="font-regular appBarNotificationText--text app-bar-notification-description">
    <div v-if="isPending">
      The current version will be available until
      <span class="font-normal appBarNotificationTitle--text">{{ ACCOUNT_MIGRATION_DATE_END }}</span>.
      Make sure to migrate your data to Dialics 2.0 in time
    </div>

    <div v-else>
      You've initiated a data migration to the new version Dialics 2.0. You can check current progress stage
    </div>
  </div>
</template>

<script>
import { ACCOUNT_MIGRATION_DATE_END } from '@/constants/accountMigrationStatus'

export default {
  name: 'AppBartNotificationDescription',
  data: () => ({
    ACCOUNT_MIGRATION_DATE_END: ACCOUNT_MIGRATION_DATE_END.dateOne
  }),
  props: {
    isPending: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

.app-bar-notification-description {
  @media screen and (max-width: 1440px) {
    width: 400px;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
  }
}
</style>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M13.1456 15.0166C12.8479 15.3046 12.8401 15.7794 13.1282 16.0771C13.4162 16.3747 13.891 16.3825 14.1887 16.0945L17.6876 12.7086C17.8375 12.5635 17.9203 12.3624 17.9158 12.1538C17.9114 11.9452 17.8203 11.7478 17.6643 11.6091L14.1655 8.49857C13.8559 8.22336 13.3818 8.25121 13.1066 8.56078C12.8314 8.87034 12.8593 9.3444 13.1688 9.61961L15.2226 11.4455L7.91755 11.2785C7.50345 11.2691 7.16008 11.5971 7.15061 12.0112C7.14115 12.4253 7.46917 12.7687 7.88328 12.7781L15.2839 12.9473L13.1456 15.0166Z"/>
  </svg>
</template>

<script>
export default {
  name: 'Arrow-right-icon',
  inheritAttrs: false,
}
</script>
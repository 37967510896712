<template>
  <svg v-bind="$attrs" class="drawerIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path stroke="none" d="M10.0007 14.1663C12.3018 14.1663 14.1673 12.3009 14.1673 9.99967C14.1673 7.69849 12.3018 5.83301 10.0007 5.83301C7.69946 5.83301 5.83398 7.69849 5.83398 9.99967C5.83398 12.3009 7.69946 14.1663 10.0007 14.1663Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'TargetIcon',
  inheritAttrs: false,
}
</script>

<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-11 cancel-btn"
      :class="hover
        ? 'pink cancelButtonHoverColor--text'
        : 'cancelButtonColor--text'"
      outlined
      :height="height"
      :ripple="false"
    >
      <span class="font-normal">
        <slot></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'CancelButton',
  inheritAttrs: false,
  props: {
    height: {
      type: String | Number,
      default: 48
    }
  },
}
</script>

import { Types as authTypes } from '@/store/modules/auth'

export default function syncNumbers({ next, store }) {
  const account = store.getters[authTypes.getters.GET_CURRENT_ACCOUNT]

  if (account.id !== 3) {
    return next({ name: 'NotFound' })
  }

  return next()
}
<template>
  <v-badge
    :value="visibleBadge"
    class="pulse"
    color="error"
    bordered
    overlap
    dot
  >
    <v-menu
      v-model="menu"
      content-class="accountsMenu"
      allow-overflow
      :close-on-content-click="false"
      min-width="420"
      max-width="600"
      max-height="610"
      offset-y
      nudge-bottom="17"
      nudge-left="250"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          size="20"
        >$bellIcon</v-icon>
      </template>

      <v-list
        class="accountsList pa-10"
        v-if="notifications.length"
      >
        <div
          v-for="(item, index) in notifications"
          :key="index"
        >
          <v-divider v-if="index"></v-divider>

          <span class="headerActionColor--text font-xs">{{ formatDate(item['created_at']) }}</span>

          <v-list-item class="pa-0">
            <v-hover #default="{ hover }">
              <v-alert
                class="accountsList"
                border="left"
                colored-border
                :color="item['read_at'] ? 'primary' : 'error'"
                :elevation="hover ? 5 : 0"
                width="100%"
              >
                <div class="d-flex align-start justify-space-between">
                  <div
                    class="accountsList__notification-content tableBodyText--text"
                    v-html="messages(item)"
                  />

                  <TooltipAction zIndex="1000" bottom message="Mark as read" v-if="!item['read_at']">
                    <template #activator>
                      <v-icon class="ml-3"  @click="markRead(item.id)">$closeIcon</v-icon>
                    </template>
                  </TooltipAction>
                </div>
              </v-alert>
            </v-hover>
          </v-list-item>
        </div>
      </v-list>

      <v-list class="accountsList pa-10" v-else>
        <span class="tableBodyText--text">No notifications</span>
      </v-list>
    </v-menu>

  </v-badge>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'

import { getNotifications, markReadNotifications } from '@/api/header-routes'

import { formatDate } from '@/helper/app-helper'

export default {
  name: 'MenuNotification',
  inheritAttrs: false,
  components: { TooltipAction },
  data: () => ({
    menu: false,
    notificationsInterval: null,
    notifications: [],
    countUnreadNotification: 0
  }),
  computed: {
    visibleBadge() {
      return this.notifications.some(n => !n['read_at'])
    }
  },
  mounted() {
    this.loadNotifications()
    this.setNotificationsInterval()
  },
  methods: {
    openMenu() {
      this.menu = true
    },
    checkUrl(url) {
      return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
    },
    messages({ data = {} }) {
      const rows = data.message.split('\n')

      const results = rows.map(textRow => {
          if (this.checkUrl(textRow)) {
            const res = textRow.split(' ').map(text => {
              if (this.checkUrl(text)) {
                return `<a target="_blank" href="${text}">${text}</a>`
              }
              return text
            })

            return res.join(' ')
          }
          return textRow
      })

      return results.join('\n')
    },
    setNotificationsInterval() {
      clearInterval(this.notificationsInterval)

      setInterval(() => {
        this.loadNotifications()
      }, 120000)
    },
    async loadNotifications() {
      const { success, payload } = await getNotifications()

      if (success) {
        const unReadCount = payload.filter(p => !p.read_at).length

        if (unReadCount !== this.countUnreadNotification) {
          this.openMenu()
          this.countUnreadNotification = unReadCount
        }

        this.notifications = payload
      }
    },
    formatDate(date) {
      return formatDate(date)
    },
    async markRead(id) {
      this.checkNotification(id)

      const { success, payload } = await markReadNotifications({ id })
    },
    checkNotification(id) {
      const currentNotificationIndex = this.notifications.findIndex(n => n.id === id)

      if (currentNotificationIndex + 1) {
        this.notifications[currentNotificationIndex]['read_at'] = true
      }
    },
    updateNotificationAfterRead() {
      this.notifications = this.notifications.filter(n => !n.read_at)
    }
  },
  watch: {
    menu: {
      handler(menu) {
        if (!menu) {
          this.updateNotificationAfterRead()
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.notificationsInterval)
  },
}
</script>

import { getAccessToken } from '@/helper/auth-helper'

export default function error({ next, store }) {
  if (!getAccessToken()) {
    return next({ name: 'AuthLogin' })
  }

  if (store.state.auth.role === 'Manager') {
    return next({ name: 'Dashboard' })
  }

  return next({ name: 'Reporting' })
}
<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini && checkLg"
    mini-variant-width="80"
    mobile-breakpoint="1280"
    width="260"
    color="leftDrawerBg"
    app
  >
    <v-list-item
      link
      :ripple="false"
      dense
      @click="actionDrawer"
    >
      <v-list-item-icon v-if="mini && checkLg">
        <v-icon color="drawerMenuIcon">$menuIcon</v-icon>
      </v-list-item-icon>
      
      <v-list-item-icon class="ml-8" v-else>
        <v-icon color="drawerMenuIcon">$backIcon</v-icon>
      </v-list-item-icon>
      
      <v-list-item-avatar class="rounded-0 mr-4">
        <v-img
          alt="logo"
          contain
          src="/svg/logo-icon.svg"
          max-width="30px"
        />
      </v-list-item-avatar>
      <v-list-item-title class="font-logo pt-1">Admin</v-list-item-title>
    </v-list-item>
    
    <v-list class="pa-0" dense>
      <v-list-item
        v-for="(item, idx) in items"
        :key="idx"
        class="nav-item"
        :ripple="false"
        :to="item.route"
        link
        exact
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">{{ `$${item.icon}Icon` }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item
        @click="logOut"
        class="nav-item"
        :ripple="false"
      >
        <v-list-item-icon>
          <v-icon color="drawerMenuIcon">$turnOffIcon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-normal navItem menuTitleColor--text">
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'AdminLeftDrawer',
  inheritAttrs: false,
  props: {
    mainDrawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    mini: true,
    items: [
      { title: 'Rates', icon: 'reporting', route: { name: 'Rates' } },
      { title: 'Personal Rate', icon: 'buyer', route: { name: 'PersonalRate' } },
      { title: 'Pricing', icon: 'target', route: { name: 'Pricing' } },
      { title: 'Managers', icon: 'vendor', route: { name: 'Managers' } },
      { title: 'Move Numbers', icon: 'campaign', route: { name: 'MoveNumbers' } },
      { title: 'Plivo Price', icon: 'integrations', route: { name: 'PlivoPrice' } },
    ],
  }),
  computed: {
    checkLg() {
      return this.$vuetify.breakpoint.width > 1279
    },
    drawer: {
      get() {
        return this.mainDrawer
      },
      set(value) {
        this.$emit('update:mainDrawer', value)
      }
    }
  },
  methods: {
    ...mapActions({
      logOut: authTypes.actions.LOG_OUT,
    }),
    actionDrawer() {
      if (this.checkLg) {
        this.mini = !this.mini
      } else {
        this.drawer = !this.drawer
      }
    },
  },
  watch: {
    mini: {
      immediate: true,
      handler(value) {
        this.$emit('changeMini', value)
      }
    }
  }
}
</script>

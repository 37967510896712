function getAccessToken() {
  return localStorage.getItem('access_token')
}

function setAccessToken(token) {
  return localStorage.setItem('access_token', token)
}

function removeAccessToken() {
  return localStorage.removeItem('access_token')
}

function getAccountId() {
  return localStorage.getItem('account_id')
}

function setAccountId(id) {
  return localStorage.setItem('account_id', id)
}


function checkAndSetAccountId({ accounts }) {
  let account = accounts.find(account => account.slug === getAccountId())

  if (!account) {
    account = accounts[0]
  }

  setAccountId(account.slug)
}

function setUserNameAndEmailForCrispChat({ name, email }) {
  if (!!window.$crisp) {
    $crisp.push(['safe', true])
    $crisp.push(['set', 'user:nickname', [name]])
    $crisp.push(['set', 'user:email', [email]])
  }
}

export {
  getAccessToken, setAccessToken, removeAccessToken, getAccountId, setAccountId, checkAndSetAccountId, setUserNameAndEmailForCrispChat
}
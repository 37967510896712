import { checkUserAccess, getRoles, checkUserSubAccount, getLogout } from '@/api/auth-routes'
import { getStatusMigration } from '@/api/migration-routes'
import { removeNamespace } from '@/helper/namespace-helper'
import { checkAndSetAccountId, removeAccessToken, getAccountId, getAccessToken, setAccountId, setUserNameAndEmailForCrispChat } from '@/helper/auth-helper'
import { Types as indexTypes } from '@/store'
import {ACCOUNT_MIGRATION_STATUSES} from '@/constants/accountMigrationStatus';

const NAME_SPACE = 'auth/'

export const Types = {
  getters: {
    IS_MANAGER: `${NAME_SPACE}IS_MANAGER`,
    IS_VENDOR: `${NAME_SPACE}IS_VENDOR`,
    IS_BUYER: `${NAME_SPACE}IS_BUYER`,
    GET_USER_NAME: `${NAME_SPACE}GET_USER_NAME`,
    GET_CURRENT_ACCOUNT: `${NAME_SPACE}GET_CURRENT_ACCOUNT`,
    CALL_LOG_PIN_ENABLED: `${NAME_SPACE}CALL_LOG_PIN_ENABLED`,
    GET_TWO_FACTOR_AUTH_ID: `${NAME_SPACE}GET_TWO_FACTOR_AUTH_ID`,
    IS_COMPLETED_MIGRATION: `${NAME_SPACE}IS_COMPLETED_MIGRATION`,
    IS_PENDING_MIGRATION: `${NAME_SPACE}IS_PENDING_MIGRATION`,
    IS_IN_PROGRESS_MIGRATION: `${NAME_SPACE}IS_IN_PROGRESS_MIGRATION`,
    IS_FAILED_MIGRATION: `${NAME_SPACE}IS_FAILED_MIGRATION`,
  },
  mutations: {
    SET_ROLES: `${NAME_SPACE}SET_ROLES`,
    SET_ACCOUNT_ID: `${NAME_SPACE}SET_ACCOUNT_ID`,
    SET_ACCOUNT_ROLE: `${NAME_SPACE}SET_ACCOUNT_ROLE`,
    SET_ACCOUNT_TIMEZONE: `${NAME_SPACE}SET_ACCOUNT_TIMEZONE`,
    SET_ACCOUNT_PBX: `${NAME_SPACE}SET_ACCOUNT_PBX`,
    SET_ACCOUNT_PERMISSION: `${NAME_SPACE}SET_ACCOUNT_PERMISSION`,
    SET_SELECT_ACCOUNTS: `${NAME_SPACE}SET_SELECT_ACCOUNTS`,
    SET_ACCOUNT_SUBSCRIPTION: `${NAME_SPACE}SET_ACCOUNT_SUBSCRIPTION`,
    SET_ACCOUNTS: `${NAME_SPACE}SET_ACCOUNTS`,
    SET_USER: `${NAME_SPACE}SET_USER`,
    SET_TWO_FACTOR_AUTH_ID: `${NAME_SPACE}SET_TWO_FACTOR_AUTH_ID`,
    SET_MIGRATION_STATUS_ACCOUNT: `${NAME_SPACE}SET_MIGRATION_STATUS_ACCOUNT`,
    REDIRECT_FORBIDDEN: `${NAME_SPACE}REDIRECT_FORBIDDEN`,
    REDIRECT_ACCOUNT_BLOCKED: `${NAME_SPACE}REDIRECT_ACCOUNT_BLOCKED`,
    APP_VERSION_CHANGED: `${NAME_SPACE}APP_VERSION_CHANGED`,
    UPDATE_CALL_LOG_PIN_ENABLED: `${NAME_SPACE}UPDATE_CALL_LOG_PIN_ENABLED`,
    RESET_STATE: `${NAME_SPACE}RESET_STATE`,
  },
  actions: {
    GET_CHECK: `${NAME_SPACE}GET_CHECK`,
    GET_LOAD_ROLES: `${NAME_SPACE}GET_LOAD_ROLES`,
    CHECK_USER_SUB_ACCOUNT: `${NAME_SPACE}CHECK_USER_SUB_ACCOUNT`,
    LOG_OUT: `${NAME_SPACE}LOG_OUT`,
    REMOVE_ACCOUNT_DATA: `${NAME_SPACE}REMOVE_ACCOUNT_DATA`,
    GET_MIGRATION_STATUS_ACCOUNT: `${NAME_SPACE}GET_MIGRATION_STATUS_ACCOUNT`
  },
}

const _types = removeNamespace(NAME_SPACE, Types)

const defaultState = () => ({
  roles: [],
  accounts: [],
  user: null,
  accountId: null,
  role: null,
  timezoneId: null,
  pbx: true,
  permissions: null,
  selectAccounts: false,
  subscription: true,
  logoutRedirect: false,
  redirectForbidden: false,
  redirectAccountBlocked: false,
  redirectPbx: false,
  appVersionChanged: false,
  statusMigration: null
})

const state = defaultState()

const getters = {
  [_types.getters.IS_MANAGER]: state => state.role === 'Manager',

  [_types.getters.IS_VENDOR]: state => state.role === 'Vendor',

  [_types.getters.IS_BUYER]: state => state.role === 'Buyer',

  [_types.getters.IS_COMPLETED_MIGRATION]: state => state.statusMigration === ACCOUNT_MIGRATION_STATUSES.completed,

  [_types.getters.IS_PENDING_MIGRATION]: state => state.statusMigration === ACCOUNT_MIGRATION_STATUSES.pending,

  [_types.getters.IS_IN_PROGRESS_MIGRATION]: state => state.statusMigration === ACCOUNT_MIGRATION_STATUSES.inProgress,

  [_types.getters.IS_FAILED_MIGRATION]: state => state.statusMigration === ACCOUNT_MIGRATION_STATUSES.failed,

  [_types.getters.GET_USER_NAME]: state => !!state.user ? state.user['first_name'] : 'User',

  [_types.getters.GET_CURRENT_ACCOUNT]: state => state.accounts.find(account => account.slug === getAccountId()),
  
  [_types.getters.CALL_LOG_PIN_ENABLED]: state => {
    const account = state.accounts.find(account => account.slug === getAccountId())
    
    return account.hasOwnProperty('call_log_pin_enabled') && account['call_log_pin_enabled']
  },
  [_types.getters.GET_TWO_FACTOR_AUTH_ID]: state => state.user['two_factor_auth_id'],
}

const mutations = {
  [_types.mutations.SET_ROLES](state, roles) {
    state.roles = roles
  },

  [_types.mutations.SET_ACCOUNT_ID](state, accountId) {
    state.accountId = accountId
  },

  [_types.mutations.SET_ACCOUNT_ROLE](state, role) {
    state.role = role
  },

  [_types.mutations.SET_ACCOUNT_TIMEZONE](state, timezoneId) {
    state.timezoneId = timezoneId
  },

  [_types.mutations.SET_ACCOUNT_PBX](state, pbx) {
    state.pbx = pbx
  },

  [_types.mutations.SET_ACCOUNT_PERMISSION](state, permissions) {
    state.permissions = permissions
  },

  [_types.mutations.SET_ACCOUNT_SUBSCRIPTION](state, subscription) {
    state.subscription = subscription
  },

  [_types.mutations.SET_SELECT_ACCOUNTS](state, selectAccounts) {
    state.selectAccounts = selectAccounts
  },

  [_types.mutations.SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts
  },

  [_types.mutations.SET_USER](state, user) {
    state.user = user
  },

  [_types.mutations.SET_MIGRATION_STATUS_ACCOUNT](state, status) {
    state.statusMigration = status
  },

  [_types.mutations.SET_TWO_FACTOR_AUTH_ID](state, id) {
    state.user = Object.assign({}, state.user, { 'two_factor_auth_id': id })
  },

  [_types.mutations.REDIRECT_FORBIDDEN](state, redirect) {
    state.redirectForbidden = redirect
  },

  [_types.mutations.REDIRECT_ACCOUNT_BLOCKED](state, redirect) {
    state.redirectAccountBlocked = redirect
  },
  
  [_types.mutations.APP_VERSION_CHANGED](state, value) {
    state.appVersionChanged = value
  },
  
  [_types.mutations.UPDATE_CALL_LOG_PIN_ENABLED](state, value) {
    const currentAccountIndex = state.accounts.findIndex(a => a.slug === getAccountId())
    
    if (currentAccountIndex + 1) {
      state.accounts = state.accounts.map((a, i) => {
        return i === currentAccountIndex
          ? { ...a, 'call_log_pin_enabled': value }
          : a
      })
    }
    
  },

  [_types.mutations.RESET_STATE](state) {
    state = Object.assign(state, defaultState())
  },
}

const actions = {
  async [_types.actions.GET_CHECK]({ commit, dispatch, state }) {
    const { success, payload, message } = await checkUserAccess()

    if (success) {
      if (!state.roles.length) {
        await dispatch(_types.actions.GET_LOAD_ROLES)
      }
      
      const user = payload.user
      commit(_types.mutations.SET_USER, payload.user)
      commit(_types.mutations.SET_ACCOUNTS, payload.accounts)
      
      if (!!user && user.hasOwnProperty('select') && !!user.select) {
        commit(_types.mutations.SET_SELECT_ACCOUNTS, user.select)
        commit(_types.mutations.SET_ACCOUNT_ROLE, 'select_accounts')
      } else {
        commit(_types.mutations.SET_SELECT_ACCOUNTS, false)
        checkAndSetAccountId({ accounts: payload.accounts, roles: state.roles })
        
        await dispatch(_types.actions.CHECK_USER_SUB_ACCOUNT, getAccountId())

        setUserNameAndEmailForCrispChat({
          name: payload.user['first_name'],
          email: payload.user.email
        })
      }
    } else {
      !!message && commit(indexTypes.mutations.SET_MESSAGE, { message, type: 'error-bg' }, { root: true })

      removeAccessToken()
    }
  },

  async [_types.actions.GET_LOAD_ROLES]({ commit }) {
    const { success, payload, message } = await getRoles()

    if (success) {
      commit('SET_ROLES', payload)
    } else {
      !!message && commit(indexTypes.mutations.SET_MESSAGE, { message, type: 'error-bg' }, { root: true })
    }
  },

  async [_types.actions.CHECK_USER_SUB_ACCOUNT]({ commit, dispatch }, accountId) {
    const { success, payload, message } = await checkUserSubAccount(accountId)

    if (success) {

      if (payload.role === 'Manager') {
        await dispatch(_types.actions.GET_MIGRATION_STATUS_ACCOUNT)
      }

      commit(_types.mutations.SET_ACCOUNT_ID, payload.account.slug)
      setAccountId(payload.account.slug)
      commit(_types.mutations.REDIRECT_ACCOUNT_BLOCKED, !payload['account_enabled'])
      commit(_types.mutations.SET_ACCOUNT_ROLE, payload.role)
      commit(_types.mutations.SET_ACCOUNT_TIMEZONE, payload.account['timezone_id'])
      commit(_types.mutations.SET_ACCOUNT_PBX, payload.pbx)
      commit(_types.mutations.SET_ACCOUNT_PERMISSION, payload.permissions)
      commit(_types.mutations.SET_ACCOUNT_SUBSCRIPTION, payload.subscription)
      
      if (!payload['account_enabled']) {
        commit(indexTypes.mutations.SET_MESSAGE, { message: 'This account has been blocked', type: 'error-bg' }, { root: true })
      }
    } else {
      !!message && commit(indexTypes.mutations.SET_MESSAGE, { message, type: 'error-bg' }, { root: true })
    }
  },

  async [_types.actions.LOG_OUT]({ commit }) {
    if (!!getAccessToken()) {
      const { success, message } = await getLogout()

      if (success) {
        !!message && commit(indexTypes.mutations.SET_MESSAGE, { message, type: 'success-bg' }, { root: true })
      }
    }
    commit('RESET_STATE')
    removeAccessToken()
  },

  async [_types.actions.REMOVE_ACCOUNT_DATA]({ commit }) {
    commit('RESET_STATE')
    removeAccessToken()
  },

  async [_types.actions.GET_MIGRATION_STATUS_ACCOUNT]({ commit }){
    const { success, payload } = await getStatusMigration()

    if (success) {
      commit(_types.mutations.SET_MIGRATION_STATUS_ACCOUNT, payload.name)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
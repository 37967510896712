import axios from 'axios'
import store from '@/store'
import { Types as authTypes } from '@/store/modules/auth'
import { getAccessToken, removeAccessToken } from '@/helper/auth-helper'
import { checkAppVersion } from '@/helper/app-helper'

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api/'
})

axiosApi.interceptors.request.use(
  config => {
    const token = getAccessToken()

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }
    return config
  },

  error => Promise.reject(error)
)

axiosApi.interceptors.response.use(
  response => {
    checkAppVersion(response.headers)
    // store.commit(authTypes.mutations.APP_VERSION_CHANGED, checkAppVersion(response.headers))
    
    if (response.headers['content-type'].indexOf('text/csv;') + 1) {
      return Promise.resolve(response)
    }
    return Promise.resolve(response.data)
  },
  error => {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 303:
          store.commit(authTypes.mutations.SET_ACCOUNT_PBX, false)
          break
        case 401:
          removeAccessToken()
          store.commit(authTypes.mutations.RESET_STATE)
          break
        case 402:
          store.commit(authTypes.mutations.SET_ACCOUNT_SUBSCRIPTION, false)
          break
        case 403:
          store.commit(authTypes.mutations.REDIRECT_FORBIDDEN, true)
          break
        case 409:
          store.commit(authTypes.mutations.REDIRECT_ACCOUNT_BLOCKED, true)
          break
      }
    }

    const message = error?.response ? error.response.data.message : error.message
    return Promise.resolve({
      success: false,
      message
    })
  }
)

export default axiosApi

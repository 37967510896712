<template>
  <svg v-bind="$attrs" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1414 10.0711H2.99924" stroke="#1A1C1D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.0703 3V17.1421" stroke="#1A1C1D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  inheritAttrs: false,
}
</script>
